.app-footer-boutiq-mark {
    opacity: 0.8;
    color: #B5B5B5;
    -webkit-text-fill-color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    user-select: none;
    position: absolute;
    bottom: 12px;
    text-align: center;
    text-decoration: none;
}

@media (max-width: 479px) {
    .app-footer-boutiq-mark {
        font-size: 10px;
        line-height: 16px;
        bottom: 2px;
    }
}
