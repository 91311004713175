.host-data-container {
    display: grid;
    grid-auto-flow: column;
    gap: 0 18px;
    cursor: pointer;
    position: relative;
    width: 100%;
    transition: .14s background, .14s color;
    grid-template-columns: auto 1fr;
    padding: 16px 6px;
    border-bottom: 2px solid rgba(182, 182, 182, 0.3);
    animation: onenter-list-item-animation .2s forwards;

    .host-avatar-container {
        z-index: 1;
        grid-column: 1;
        grid-row: 1 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 112px;
        width: 112px;
        overflow: hidden;
        border-radius: 6px;
        position: relative;
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);

        &:before {
            border-radius: 6px;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #e5e5e5;

        }

        .host-avatar {
            object-fit: cover;
            height: 100%;
            width: 100%;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
            opacity: 0;

            &.loaded {
                animation: onenter-list-item-animation .2s forwards;
            }

            &.initials {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 80%;
                width: 80%;
                overflow: hidden;
                border-radius: 50%;
                position: initial;
                font-size: 20px;
                font-weight: 500;
                background-color: white;
                opacity: 0;
                animation: onenter-list-item-animation .2s forwards;
            }
        }

        
    }

    .host-details-data-container {
        display: grid;
        grid-auto-flow: row;

        .host-name-wrapper {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            width: 100%;
            overflow: hidden;

            .host-name {
                z-index: 1;
                color: #3F3F3F;
                font-size: 16px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .host-next-availability {
            color: #3F3F3F;
            font-size: 14px;
            font-weight: 500;
        }

        .host-bio {
            z-index: 1;
            color: #66676C;
            font-size: 14px;
            margin: 8px 0 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            transition: max-height .4s cubic-bezier(0.075, 0.82, 0.165, 1);
            overflow: hidden;
            max-height: var(--default-max-height);
            animation: close 0s .4s forwards;

            &.open {
                animation: open 0s forwards;
            }

            @keyframes open {
                from {
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                }

                to {
                    line-clamp: initial;
                    -webkit-line-clamp: initial;
                }
            }

            @keyframes close {
                from {
                    line-clamp: initial;
                    -webkit-line-clamp: initial;
                }

                to {
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                }
            }
        }

        .host-description-toggle-button {
            justify-self: flex-start;
            color: #66676C;
            font-size: 14px;
            font-weight: 600;
            padding: 6px 6px 6px 0;
            opacity: 0;
            pointer-events: none;
            visibility: hidden;

            &.available {
                opacity: 1;
                pointer-events: all;
                visibility: visible;
            }

            .host-description-toggle-button-animated-labels-container {
                overflow: hidden;
                position: relative;
                height: 1.2em;

                .host-description-toggle-button-label {
                    line-height: 1.2em;
                    transform: translateY(0);
                    opacity: 1;
                    will-change: opacity, transform;
                    white-space: nowrap;
                    text-align: left;
                    transition: .1s opacity, .1s transform ease-in-out;
                }

                &.open {
                    .host-description-toggle-button-label {
                        transform: translateY(-100%);
                        opacity: 0;
                        transition: .1s opacity, .1s transform ease-in-out;

                        &.active {
                            transform: translateY(-100%);
                            opacity: 1;
                            transition: .3s opacity, .3s transform ease-in-out;
                        }
                    }
                }
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(241, 239, 239, 1);
        opacity: 0;
        transition: .14s opacity;
        will-change: opacity;
        pointer-events: none;
    }

    &:hover {
        &:before {
            opacity: .5;
        }
    }

    &.checked {
        background-color: #F1EFEF;
    }

    &.scheduled {
        &.selected {
            &:before {
                opacity: 1;
            }
        }
    }

    &:not(.scheduled) {
        .selected-tag {
            display: none;
            pointer-events: none;
        }
    }
}

@media (max-width: 479px) {
    .host-data-container {
        padding: 14px 4px;
    
        &:hover {
            &:before {
                opacity: 0;
            }
        }

        .host-avatar-container {
            height: 80px;
            width: 80px;
    
            .host-avatar {
                font-size: 18px;
    
                &.initials {
                    font-size: 18px;
                }
            }
        }
    
        .host-details-data-container {
            .host-name-wrapper {
                gap: 8px;
              
                .host-name {
                    font-size: 14px;
                }
            }
    
            .host-next-availability {
                font-size: 12px;
            }
    
            .host-bio {
                font-size: 12px;
                margin: 4px 0 0 0;
            }
    
            .host-description-toggle-button {
                font-size: 12px;
                padding: 10px 4px 4px 0;
            }
        }
    }
}
