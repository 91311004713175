.modal-overlay {
    position: absolute;
    will-change: opacity;
    background-color: rgba(0, 0, 0, 0.24);
    animation: modalDialogBackdropAnimation .15s ease-in-out forwards;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.4em;

    .modal {
        width: 100%;
        background: white;
        border-radius: var(--border-radius-s);
        box-shadow: inset 0 4px 0 0 var(--main-color);
        padding: 32px;
        padding-top: 64px;
        z-index: 100;
        position: relative;
        opacity: 0;
        will-change: opacity, transform;
        animation: dialogContainerAnimation .15s .1s ease-in-out forwards;
    }

    .close-button {
        position: absolute;
        right: 32px;
        top: 20px;
    }
}

@keyframes modalDialogBackdropAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes dialogContainerAnimation {
    0% {
        opacity: 0;
        transform: translate3d(0, 10px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
