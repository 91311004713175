.mobile-drawer-menu {
	display: grid;
    grid-auto-flow: column;
    gap: 6px;
    align-items: center;
    justify-items: center;
	height: 64px;
    transition: .4s opacity;
    will-change: opacity;

    &.mobile-enabled-full-screen {
        opacity: 0;
        pointer-events: none;
    }
}
