.big-calendar-container {
    display: grid;
    grid-auto-flow: row;
    border: 1px solid #3F3F3F;
    border-radius: 2px;
    min-width: 156px;
    position: relative;

    .big-calendar-header-container {
        background-color: #A9D6D0;
        font-size: 18px;
        font-weight: 600;
        padding: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1em;
    }

    .big-calendar-content {
        display: grid;
        grid-auto-flow: row;
        gap: 20px;
        padding: 16px 22px;

        .big-calendar-day {
            font-size: 40px;
            line-height: 1em;
            font-weight: 600;
            justify-self: center;
        }

        .big-calendar-day-time {
            line-height: 1em;
            font-size: 18px;
            justify-self: center;
            display: flex;
            flex-flow: column;
            align-items: center;

            .big-calendar-day-timezone {
                color: rgba(0, 0, 0, 0.4);
                -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
                font-size: 12px;
                padding-bottom: 2px;
            }
        }
    }

    .checkmark-container {
        position: absolute;
        left: 100%;
        top: 100%;
        transform: translate3d(-50%, -50%, 0);
    }
}

@media (max-width: 479px) {
    .big-calendar-container {
        min-width: 110px;
        
        .big-calendar-header-container {
            font-size: 16px;
            padding: 12px;
        }
    
        .big-calendar-content {
            gap: 10px;
            padding: 10px 20px;
    
            .big-calendar-day {
                font-size: 26px;
            }
    
            .big-calendar-day-time {
                font-size: 14px;
            }
        }

        .checkmark-container {
            svg {
                height: 36px;
                width: 36px;
            }
        }
    }
}
