.mobile-parent-details-container {
    overflow: hidden;
    align-content: flex-start;
    transition: .25s height, .25s transform;
    will-change: transform;
    display: flex;
    flex-flow: column;
    gap: 12px;
    padding: 0 0 16px 0;

    &.mobile-small {
        transform: translateY(-40px);
        height: calc(100% + 40px);

        .mobile-product-details-description-wrapper {
            flex-grow: 0;
            transition: .1s flex-grow;

            .mobile-main-image-wrapper {}
        }

        .mobile-image-gallery-wrapper {
            height: 0;
        }

        .mobile-product-description-wrapper {
            flex-grow: 1;
            overflow-y: auto;

            .mobile-product-description-container {
                .mobile-product-description-content {
                    height: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    &.mobile-no-main-image {
        transform: translateY(0);
        height: 100%;

        .mobile-product-details-description-wrapper {
            flex-grow: 0;
        }

        .mobile-product-description-wrapper {
            flex-grow: 1;
            overflow-y: auto;

            .mobile-product-description-container {
                .mobile-product-description-content {
                    height: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    .mobile-image-gallery-wrapper,
    .mobile-product-details {
        padding: 0 16px;
    }

    .mobile-product-details-description-wrapper {
        flex-grow: 1;
        transition: .5s flex-grow;
        will-change: transform;
        padding: 0 16px;

        .mobile-main-image-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .empty-image {
                border-radius: 6px;
            }
        }

        .mobile-price-container {
            color: #3F3F3F;
            -webkit-text-fill-color: #3F3F3F;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .mobile-product-details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 18px;

        .mobile-product-title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
        }
    }

    .mobile-product-description-wrapper {
        .mobile-product-description-container {
            padding: 0 16px;

            .mobile-products-actual-options {
                display: grid;
                grid-auto-flow: row;
                gap: 16px;
            }

            .mobile-product-description-content {
                height: 0;
                max-height: 0;
                overflow: hidden;
                transition: .3s ease-in-out max-height;

                .mobile-product-description {
                    padding-top: 24px;

                    .mobile-product-description-html-content * {
                        all: revert;
                        font-size: 16px;
                       
                    }
                    
                    .mobile-product-description-html-content {
                        a {
                            color: -webkit-link;
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
