.boutiq-select-button-group {
    display: grid;
    grid-auto-flow: row;
    gap: 8px;
    position: relative;

    .boutiq-select-button-group-label {
        font-size: 16px;
    }
}

.select {
    height: 50px;
    position: relative;
    min-width: 200px;
    
    svg {
        position: absolute;
        right: 12px;
        top: calc(50% - 3px);
        width: 10px;
        height: 6px;
        stroke-width: 2px;
        stroke: #9098A9;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        pointer-events: none;
    }
     
    
    select {
        height: 100%;
        -webkit-appearance: none;
        padding: 7px 40px 7px 12px;
        width: 100%;
        border: 1px solid #E8EAED;
        border-radius: 5px;
        background: white;
        box-shadow: 0 1px 3px -2px #9098A9;
        cursor: pointer;
        font-family: inherit;
        font-size: 16px;
        transition: all 150ms ease;
        &:required:invalid {
            color: #5A667F
        }
        
        option {
            color: #223254;

            &[value=""][disabled] {
                display: none;
            }
        }
        
        
        &:focus {
            outline: none;
            border-color: #0077FF;
            box-shadow: 0 0 0 2px rgba(#0077FF,.2);
        }
         
    
        &:hover + svg {
            stroke: #0077FF
        }
    }
     
}

.sprites {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
}

@media (max-width: 479px) {
    .boutiq-select-button-group {
        .boutiq-select-button-group-label {
            font-size: 14px;
        }
    }

    .select {
        select {
            font-size: 14px;
        }
    }
}
