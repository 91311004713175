.slots-timezone {
    color: rgba(0, 0, 0, 0.4);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    padding-bottom: 2px;
}

@for $i from 1 to 10 {
    .time-slot:nth-child(#{$i}) {
        &:not(.initial) {
            animation-delay: $i * 0.05s;
        }
    }
}

.time-slot {
    position: relative;
    height: 55px;
    width: 100%;
    border-top: 2px solid #ECECEC;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--base-font-color);
    -webkit-text-fill-color: var(--base-font-color);
    cursor: pointer;
    opacity: 0;
    transition: .14s background, .14s color;
    animation-name: onenter-list-item-animation;
    animation-duration: .4s;
    animation-fill-mode: forwards;

    .initial {
        animation-duration: 0s;
    }

    &:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #F1EFEF;
        transition: .14s opacity;
        opacity: 0;
        z-index: -1;
    }

    &::after {
        content: attr(time-slot-value);
        font-size: 16px;
        position: absolute;
        font-weight: 600;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        will-change: opacity;
        transition: .2s;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        &:before {
            opacity: .5;
        }
    }

    .time-slot-value {
        opacity: 1;
        will-change: opacity;
        transition: .2s;
        font-size: 16px;
    }

    &:not(.scheduled) {
        .selected-tag {
            display: none;
        }
    }

    .selected-tag {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
    }

    >span:first-child {
        font-weight: 500;
    }

    &.unavailable {
        border: 1px solid var(--light-font-color);
        color: var(--light-font-color);
        -webkit-text-fill-color: var(--light-font-color);
        cursor: auto;
    }

    &.scheduled {
        pointer-events: none;
        background: none;

        &.checked {
            background-color: #F1EFEF;

            .time-slot-value {
                opacity: 0;
            }
            
            &:after {
                opacity: 1;
            }
        }

        &.select-allowed {
            pointer-events: all;
            color: var(--base-font-color);
            -webkit-text-fill-color: var(--base-font-color);

            &.checked {
                background-color: #F1EFEF;
    
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &.selected {
        border-style: #D6D6D6;
        background-color: #F1EFEF;

        &:after {
            opacity: 1;
        }

        .time-slot-value {
            opacity: 0;
        }
    }
}

>.time-slot:last-child {
    margin-bottom: unset;
}

.no-slots-message-container {
    display: grid;
    gap: 1rem;
    justify-items: center;

    .no-slots-title {
        font-size: 1.33rem;
        font-weight: 500;
    }

    .no-slots-contact-us {
        cursor: pointer;
        text-decoration: underline;
    }
}

@media (max-width: 479px) {
    .slots-timezone {
        font-size: 10px;
    }
    
    .time-slot {
        height: 50px;

        &::after {
            font-size: 14px
        }
    
        .time-slot-value {
            font-size: 14px;
        }
    }
}
