.mobile-main-image-container-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: .2s all;

    &.mobile-rounded {
        overflow: hidden;
        border-radius: 6px;
    }

    &.mobile-animated-loader-background {
        &::before {
            opacity: 1;
            transition: .4s opacity;
        }

        &.mobile-loaded {
            &::before {
                animation-play-state: paused;
                opacity: 0;
            }
        }
    }

    .mobile-main-image-content-wrapper {
        display: block;
        padding-top: 100%;
        width: 100%;
        height: 0;
        transition: .25s padding-top;
        overflow: hidden;

        .mobile-main-image-content {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .mobile-main-image {
                object-fit: contain;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 0;
                width: 100%;
                transform: translateX(-50%);
                opacity: 0;
                will-change: opacity;
                animation: 1s fadeInImage forwards;
            }
        }
    }
}
