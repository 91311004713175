.mobile-options-list {
    display: grid;
    grid-auto-flow: row;

    .mobile-select-block-wrapper {
        width: 100%;
        animation: showProductsOptions .2s ease-in-out forwards;
        overflow: hidden;

        &:not(:first-child) {
            .mobile-select-block {
                margin-top: 12px;
            }
        }
        .mobile-select-block {
          
            position: relative;
    
            &:after {
                content: url(../../../../../../../assets/icons/mobile/down-arrow.svg);
                width: 16px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
    
            .mobile-product-sidebar-select {
                height: 48px;
                width: 100%;
                border: 1px solid #66676C;
                border-radius: 4px;
                padding: 12px 16px;
                font-size: 16px;
                appearance: none;
            }
        }
        
        @keyframes showProductsOptions {
            0% {
                max-height: 0;
            }
            100% {
                max-height: 60px;
            }
        }
    }
}
