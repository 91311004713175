.search-container {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	.row-chat {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 1fr auto;
		align-items: center;
		width: 100%;
		gap: 10px;
		padding: 24px 5%;

		.icon-chat {
			color: #cfc8bb;
			height: 30px;
			width: 30px;
			cursor: pointer;
			grid-column: 2;
		}

		.icon-badges {
			border-radius: 50%;
			text-align: center;
			width: 24px;
			height: 24px;
			font-size: 14px;
			background: var(--error-color);
			position: absolute;
			right: 10px;
			top: 12px;

			p {
				color: white;
				-webkit-text-fill-color: white;
			}
		}
	}


	.search-results {
		padding: unset;
		margin: unset;
		width: 540px;
		flex: 1;
	}
	

	.empty-results {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		margin-top: 1rem;

		p {
			display: block;
			margin-top: 24px;

			&:not(:first-child) {
				margin-top: 0;
			}
		}
	}

	.search-results {
		margin-top: 24px;
		overflow-y: auto;
		display: grid;
		grid-auto-flow: row;	
		gap: 16px;	
		width: 100%;
		align-items: flex-start;
		align-content: flex-start;
		list-style-type: none;
	}

	.load-more-button {
		height: 46px;
		width: 126px;
		display: block;
		margin: 12px auto;
		.button-loader {
			width: 26px;
			height: 26px;
		}
	}

	.empty-search-message {
		color: #B5B5B5;
        -webkit-text-fill-color: #B5B5B5;
		padding: 16px;
		text-align: center;
	}
}

@media (max-width: 767px) {
    .search-container {
        &:not(.mobile) {
            &:before {
                height: 16px;
            }

            &:after {
                top: 82px;
            }
        }
    }
}
