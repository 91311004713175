.scrollable-list-wrapper {
    overflow: hidden;
    height: 100%;
    position: relative;
    
    &:before {
        content: '';
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        background-image: linear-gradient(to bottom, #fff 20%, rgba(255, 255, 255, 0) 100%);
        height: 16px;
        z-index: 2;
    }

    &:after {
        content: '';
        left: 0;
        right: 20px;
        bottom: -8px;
        position: absolute;
        background-image: linear-gradient(to top, #fff 20%, rgba(255, 255, 255, 0) 100%);
        height: 16px;
        z-index: 2;
    }

    .scrollable-list-container {
        height: 100%;
        display: block;
        overflow-y: auto;
    }
}
