.video-call-container-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;

    &_form-position {
        flex: none;
        display: inline-table;
        min-height: auto;
        height: auto;
    }
}

.av-settings-dialog-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 20;
    padding-top: 60px;
}
