.mobile-menu-button {
    position: relative;
    opacity: 0.64;
    color: #fff;
    -webkit-text-fill-color: #fff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    text-shadow: 1px 1px 2px #000, 0 0 4px #000;
    transition: .25s text-shadow, .25s opacity;

    .mobile-counter-badge {
      position: absolute;
      font-size: 12px;
      right: -20px;
      background-color: #fff;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 1em;
      color: #000;
      -webkit-text-fill-color: #000;
      text-shadow: none;
      box-shadow: 0 0 8px rgba(0, 0, 0, .64);
      transition: .25s box-shadow, .25s color, .25s background-color;
    }

  &.mobile-opened-drawer-tab {
      opacity: 0.4;
      color: #000;
      -webkit-text-fill-color: #000;
      text-shadow: none;
      
      .mobile-counter-badge {
        background-color: rgba(0,0,0,.8);
        color: #fff;
        -webkit-text-fill-color: #fff;
        box-shadow: none;

      }

    &.mobile-active-tab {
        opacity: 1;
    }
  }
}
