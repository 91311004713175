.search-input {
    box-sizing: border-box;
    // this is an approximation of search dimentions for smaller screen
    height: 42px;
    border: 1px solid #ccc3bd;
    border-radius: var(--border-radius-m);
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    input {
        margin-left: 16px;
        height: 100%;
        width: 100%;
    }
    svg {
        cursor: pointer;
        color: #cfc8bb;
        -webkit-text-fill-color: #cfc8bb;
        margin-right: 16px;

        &.hidden-control {
            pointer-events: none;
            opacity: 0;
        }
    }

    .loader-wrapper {
        position: absolute;
        right: 0.8em;
        height: auto;

        .loader {
            height: 30px;
            width: 30px;
            border-width: 2px;
            border-right-color: #70706f;
            border-bottom-color: #70706f;
            border-top-color: #70706f;
            margin: 0;
        }
    }
}
