.mobile-search-results {
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    height: 100%;

    .loader-wrapper {
        padding: 1rem;

        .loader {
            height: 24px;
            width: 24px;
            border-width: 1px;
            margin: 0;
            animation: spin .4s ease-in infinite;
        }
    }

    .infinite-scroll-item {
        animation-name: onenter-list-item-animation;
        animation-duration: .25s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
        opacity: 0;
    }
}
