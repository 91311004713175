.available-days-list-wrapper {
    .available-days-list {
        .date-tile-wrapper {
            .date-title {
                .day-name {
                    &.animated-loader-background {
                        position: relative;
                        height: 23px;
                        width: 50px;
                    }
                }
            
                .day-tab-item {
                    &.animated-loader-background {
                        position: relative;
                        height: 20px;
                        width: 80px;
                    }
                }
            }
        }
    }
}
