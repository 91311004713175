.panel-activity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 17px 0 29px;
    font-family: var(--font-family);
    height: 46px;

    .product-count {
        margin-top: 2px;
        font-size: 14px;
        color: #999999;
    }
}

.product-row-wrapper {
    .close-icon-container {
        position: absolute;
        top: -12px;
        right: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 50%;
        cursor: pointer;
    }

    &.mobile {
		height: 100%;

        .product-row {
			display: grid;
			margin: auto;
			padding-top: 17px;
			grid-template-columns: repeat(auto-fill, 101px);
			grid-auto-rows: 155px;
			grid-gap: 12px;
			overflow-y: auto;
			height: calc(100% - 75px);
			justify-content: center;

            .product-container {
                position: relative;

                .product-overlay {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;

                    .view-product-overlay {
                        height: 100%;
                        position: relative;
                    }
                }

                .product-image {
                    overflow: hidden;
                    border-radius: var(--border-radius-s);
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                }
            }
        }
    }
}


.empty-showroom {
    &.mobile {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin: 16px;
        height: calc(100% - 124px);
    }
}

.showroom__local-mail__icon {
    margin-bottom: 25px;
}
