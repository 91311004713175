.video-spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
}

.ispinner {
    position: relative;
    width: 20px;
    height: 20px;
    transition: .25s transform;
    will-change: transform;

    &.hidden {
        transform: scale(0);
    }
}

.ispinner .ispinner-blade {
    position: absolute;
    left: 44.5%;
    top: 37%;
    width: 10%;
    height: 25%;
    border-radius: 50%/20%;
    animation: ispinner-fade 1s linear infinite;
    animation-play-state: paused;
}

.ispinner .ispinner-blade:nth-child(1) {
    animation-delay: -1.66667s;
    transform: rotate(30deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(2) {
    animation-delay: -1.58333s;
    transform: rotate(60deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(3) {
    animation-delay: -1.5s;
    transform: rotate(90deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(4) {
    animation-delay: -1.41667s;
    transform: rotate(120deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(5) {
    animation-delay: -1.33333s;
    transform: rotate(150deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(6) {
    animation-delay: -1.25s;
    transform: rotate(180deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(7) {
    animation-delay: -1.16667s;
    transform: rotate(210deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(8) {
    animation-delay: -1.08333s;
    transform: rotate(240deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(9) {
    animation-delay: -1s;
    transform: rotate(270deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(10) {
    animation-delay: -0.91667s;
    transform: rotate(300deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(11) {
    animation-delay: -0.83333s;
    transform: rotate(330deg) translate(0, -150%);
}

.ispinner .ispinner-blade:nth-child(12) {
    animation-delay: -0.75s;
    transform: rotate(360deg) translate(0, -150%);
}

.ispinner.animating .ispinner-blade {
    animation-play-state: running;
}

.ispinner.white .ispinner-blade {
    background-color: white;
}

.ispinner.gray .ispinner-blade {
    background-color: var(--main-color);
}

.ispinner.large {
    width: 35px;
    height: 35px;
}

.ispinner.large .ispinner-blade {
    width: 8.57143%;
    height: 25.71429%;
    border-radius: 50%/16.67%;
}

@-webkit-keyframes ispinner-fade {
    0% {
        opacity: 0.85;
    }

    50% {
        opacity: 0.25;
    }

    100% {
        opacity: 0.25;
    }
}

@keyframes ispinner-fade {
    0% {
        opacity: 0.85;
    }

    50% {
        opacity: 0.25;
    }

    100% {
        opacity: 0.25;
    }
}
