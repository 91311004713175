.mobile-add-button {
    font-size: 14px;
    border-radius: 5.5px;
    overflow: hidden;
    padding: 0 9px;
    position: relative;
    height: 20px;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        border-radius: 5.5px;
        will-change: opacity;
        border: 1px solid var(--main-color);
        opacity: 0;
        transition: .2s opacity;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 5.5px;
        opacity: 1;
        background-color: var(--main-color);
        will-change: opacity;
        transition: .2s opacity;
    }

    &.mobile-added {
        &:after {
            opacity: 1;
        }

        &:before {
            opacity: 0;
        }

        .mobile-add-button-label {
            transform: translateY(-100%);
            opacity: 0;
        }

        .mobile-add-button-label-active {
            transform: translateY(-100%);
            opacity: 1;
        }
    }

    .mobile-add-button-label {
        transform: translateY(0);
        color: #fff;
        -webkit-text-fill-color: #fff;
        opacity: 1;
        transition: .2s all cubic-bezier(0.075, 0.82, 0.165, 1);
        will-change: opacity, transform;
        white-space: nowrap;
    }

    .mobile-add-button-label-active {
        transform: translateY(0);
        color: var(--main-color);
        -webkit-text-fill-color: var(--main-color);
        opacity: 0;
        transition: .2s all cubic-bezier(0.075, 0.82, 0.165, 1);
        will-change: opacity, transform;
        white-space: nowrap;
    }
}
