.error-page-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr auto;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 80px 0 0;

    .error-header-container {
        display: flex;
        flex-flow: column;
        gap: 12px;

        .error-title-wrapper {
            display: flex;
            flex-flow: column;
			gap: 8px;
            margin-top: 20px;
			
            .error-title {
                font-size: 20px;
                font-weight: 600;
				line-height: 1.2em;
                text-align: center;
            }

            .error-subtitle {
                color: #3F3F3F;
                -webkit-text-fill-color: #3F3F3F;
                font-size: 18px;
                line-height: 1.1em;
                text-align: center;
            }
        }

        .shop-logo-container-wrapper {
            padding: 0;

            .shop-logo-content-wrapper {
                padding-top: 40px;
            }
        }
    }

    .error-client-feedback-form-wrapper {
        grid-auto-rows: max-content;
        padding: 12px 24px 20px;
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
        grid-gap: 24px;
        gap: 24px;
        width: 100%;
    }
}

@media (max-width: 479px) {
    .error-page-container {
        .error-header-container {
            .error-title-wrapper {
                margin-top: 12px;

                .error-title {
                    font-size: 18px;
                }

                .error-subtitle {
                    font-size: 16px;
                }
            }

            .shop-logo-container-wrapper {
                .shop-logo-content-wrapper {
                    padding-top: 32px;
                }
            }
        }

        .error-client-feedback-form-wrapper {
            padding: 12px 24px 12px;
            gap: 18px;
        }
    }
}
