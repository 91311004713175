.text-field-error-container {
    margin-top: -8px;
    font-size: 12px;
    height: 1.5em;
    overflow: hidden;
    line-height: 1.5em;
    display: flex;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);

    .text-field-error-message {
        color: var(--error-color);
        -webkit-text-fill-color: var(--error-color);
        transform: translateY(-100%);
        opacity: 0;
        transition: all .25s cubic-bezier(0.075, 0.82, 0.165, 1);
        will-change: transform, opacity;
        user-select: none;

        &.active {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

@media (max-width: 479px) {
    .text-field-error-container {
        font-size: 10px;
        height: 1.4em;
        line-height: 1.4em;
    }
}
