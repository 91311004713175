.intro-config-loader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
}

.intro-preview-container {
  max-width: 480px;
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .intro-preview-container {
    max-width: 100%;
  }
}
