.selected-host-additional-data {
    display: grid;
    grid-auto-flow: column;
    gap: 16px;
    justify-items: flex-start;
    justify-content: flex-start;

    .selected-host-for-appointment {
        font-size: 14px;
        font-weight: 500;
        color: #858585;
        -webkit-text-fill-color: #858585;

        .scheduled-host-name {
            color: #5C5C5C;
            -webkit-text-fill-color: #5C5C5C;
        }
    }

    .change-selected-host-button {
        padding-left: 16px;
        font-weight: 500;
        font-size: 14px;
        color: var(--main-color);
        -webkit-text-fill-color: var(--main-color);
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 30%;
            bottom: 30%;
            left: 0;
            border-right: 1px solid #676767;
        }
    }
}

@media (max-width: 479px) {
    .selected-host-additional-data {
        gap: 14px;
    
        .selected-host-for-appointment {
            font-size: 12px;
        }
    
        .change-selected-host-button {
            padding-left: 14px;
            font-size: 12px;
        }
    }
}
