.boutiq-back-button {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
        opacity: 0;
        pointer-events: none;
    }

    &.circle {
        border-radius: 50%;
        background-color: #F5F5F5;
    }
}

@media (max-width: 479px) {
    .boutiq-back-button {
        height: 30px;
        width: 30px;
    }
}
