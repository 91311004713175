.thank-you-page-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr auto;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 80px 0 0;

    .thank-you-header-container {
        display: flex;
        flex-flow: column;
        gap: 12px;

        .thank-you-title-wrapper {
            display: flex;
            flex-flow: column;
            margin-top: 20px;

            .thank-you-title {
                font-size: 20px;
                font-weight: 600;
                line-height: 1.2em;
                text-align: center;
            }
        }

        .shop-logo-container-wrapper {
            padding: 0;

            .shop-logo-content-wrapper {
                padding-top: 40px;
            }
        }
    }

    .thank-you-feedback-form-wrapper {
        grid-auto-rows: max-content;
        padding: 12px 24px 12px;
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
        justify-items: center;
        gap: 20px;
        width: 100%;

        .tu-grade-btn-container-csat {
            width: 75%
        }

        .tu-grade-btn-container-nps {
            width: 100%
        }

        .tu-grade-btn-container {
            display: grid;
            grid-auto-flow: column;
            margin-bottom: 10px;
            width: 100%;

            .csat {
                width: 75%;
            }

            .tu-grade-button {
                justify-self: center;
                align-self: center;
                position: relative;

                .tu-grade-button-item {
                    border: 1px solid var(--main-color);
                    cursor: pointer;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    transition: .4s color, .4s background;
                    font-size: 18px;
                    color: var(--main-color);
                    -webkit-text-fill-color: var(--main-color);

                    &:before {
                        content: '';
                        border-radius: 50%;
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: var(--main-color);
                        opacity: 0;
                        will-change: opacity;
                        transition: .15s opacity;
                    }

                    &:after {
                        content: '';
                        border-radius: 50%;
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: var(--main-color);
                        opacity: 0;
                    }

                    &:hover {
                        &:before {
                            opacity: .14;
                        }
                    }

                    &.active {
                        color: white;
                        -webkit-text-fill-color: white;
                        background-color: var(--main-color);

                        &:hover {
                            &:before {
                                opacity: 0;
                            }
                        }

                        &:after {
                            animation: .2s animateSelectedGrade ease-in-out forwards;
                        }
                    }
                }
            }
            
        }

        .tu-grade-btn-labels-container {
            display: grid;
            grid-auto-flow: column;
            margin-bottom: 20px;

            .tu-negative-feedback {
                margin-left: 2px;
                color: #66676C;
                -webkit-text-fill-color: #66676C;
                font-size: 14px;
            }

            .tu-positive-feedback {
                justify-self: flex-end;
                margin-right: 2px;
                color: #66676C;
                -webkit-text-fill-color: #66676C;
                font-size: 14px;
            }
        }

        .tu-feedback-message-container {
            width: 100%;
        }

        .tu-message {
            text-align: center;
            font-size: 16px;
        }

        .tu-btn-container {
            display: grid;
            grid-auto-flow: column;
            justify-content: center;
            align-items: center;
            gap: 0 40px;

            .tu-vote-button {
                display: grid;
                gap: 14px;
                font-size: 18px;
                font-weight: 500;

                .outlined-button {
                    border-color: rgba(86, 86, 86, 0.16);
                    width: 100px;
                    height: 100px;
                    box-sizing: border-box;
                    border-radius: 50%;
                }

                p {
                    justify-self: center;
                }
            }
        }
    }
}

@media (max-width: 479px) {
    .thank-you-page-container {
        .thank-you-header-container {
            .thank-you-title-wrapper {
                margin-top: 12px;

                .thank-you-title {
                    font-size: 18px;
                }
            }
        }

        .thank-you-feedback-form-wrapper {
            padding: 12px 24px 12px;
            gap: 18px;

            .tu-grade-btn-container {
                gap: 8px 0;

                .tu-grade-button {

                    .tu-grade-button-item {
                        width: 26px;
                        height: 26px;
                        font-size: 16px;
                    }
                }

                .tu-negative-feedback,
                .tu-positive-feedback {
                    font-size: 12px;
                }
            }

            .tu-feedback-message-container {}

            .tu-message {
                font-size: 14px;
            }

            .tu-btn-container {
                .tu-vote-button {
                    .outlined-button {}
                }
            }
        }
    }
}

@keyframes animateSelectedGrade {
    0% {
        opacity: 0;
    }

    20% {
        transform: scale(1);
        opacity: .5;
    }

    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}
