.schedule-success-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.schedule-success-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    grid-gap: 50px;
    gap: 50px;
    padding: 20px 24px;

    .schedule-confirm-custom-message {
        display: grid;
        grid-auto-flow: row;
        gap: 12px;
        font-size: 16px;

        .schedule-confirm-message {
            text-align: center;

            .schedule-confirmation-email {
                font-weight: 500;
            }
        }

        .schedule-custom-link {
            justify-self: center;
        }
    }
}

.secondary-buttons-container {
    display: grid;
    grid-auto-flow: column;
    gap: 30px;
    font-size: 16px;
    font-weight: 500;
    color: var(--main-color);
    -webkit-text-fill-color: var(--main-color);

    .footer-add-to-calendar-button {
        position: relative;
    }
}

@media (max-width: 479px) {
    .schedule-success-content {
        gap: 46px;
        padding: 20px;
    
        .schedule-confirm-custom-message {
            gap: 10px;
            font-size: 14px;
        }
    }
}
