.select-host-list {
    width: 100%;
    display: grid;
    justify-items: center;
    list-style-type: none;
    margin: 0;
    padding: 20px 24px;

    .no-hosts-message-container {
        display: grid;
        grid-gap: 1rem;
        gap: 1rem;
        justify-items: center;
    }
}

@media (max-width: 479px) {
    .select-host-list {
        padding: 20px;
    }
}
