.mobile-video-room {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    .mobile-full-screen-presenting-text {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 111;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--main-color);
        color: #fff;
        -webkit-text-fill-color: #fff;
    }

    .mobile-video-room-wrapper {
        height: 100%;
    }
}
