.mobile-quantity-input {
    display: flex;
    height: 22px;

    .mobile-decrease-button {
        .mobile-decrease-button-icon {
            position: relative;
            display: block;
            height: 12px;
            width: 12px;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                border-bottom: 1px solid var(--main-color);
            }
        }
    }

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    button {
        touch-action: manipulation;
        border-radius: 50%;
        border: 1px solid var(--main-color);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;

        &:disabled {
            opacity: .4;
            pointer-events: none;
        }
    }

    .mobile-count-field {
        display: inline-block;
        vertical-align: top;
        max-width: 30px;
        text-align: center;
    }

    .mobile-increase-button {
        .mobile-increase-button-icon {
            position: relative;
            display: block;
            height: 12px;
            width: 12px;

            &:before {
                content: '';
                left: 0;
                right: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border-bottom: 1px solid var(--main-color);
            }

            &:after {
                content: '';
                top: 0;
                bottom: 0;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border-right: 1px solid var(--main-color);
            }
        }
    }
}
