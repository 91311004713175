.all-host-data-container {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 18px;
    gap: 0 18px;
    cursor: pointer;
    position: relative;
    width: 100%;
    transition: .14s background, .14s color;
    grid-template-columns: auto 1fr;
    padding: 16px 6px;
    border-bottom: 2px solid rgba(182,182,182,0.3);

    .host-name {
        z-index: 1;
        color: #3F3F3F;
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.checked {
        background-color: #F1EFEF;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #f1efef;
        opacity: 0;
        transition: .14s opacity;
        will-change: opacity;
        pointer-events: none;
    }

    &:hover {
        &:before {
            opacity: .5;
        }
    }
}


@media (max-width: 479px) {
    .all-host-data-container {
        padding: 14px 4px;
    
        .host-name {
            font-size: 14px;
        }
    }   
}
