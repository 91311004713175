.copy-to-clipboard {
    &.is-clickable {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-left: calc(1em - 4px);
        line-height: 1em;

        .copy-link-icon {
            display: flex;
            opacity: 0;
            transition: .25s opacity;

            &.copied {
                opacity: 1;
            }
        }
    }
}
