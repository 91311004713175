.schedule-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr auto;
    gap: 32px;
    height: 100%;
    width: 100%;
}

.client-name-form-wrapper {
    padding: 24px 24px 0 24px;
    align-self: flex-start;
}

@media (max-width: 479px) {
    .client-name-form-wrapper {
        padding: 20px 20px 0 20px;
    }
}
