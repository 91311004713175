.draft-order-footer {
  bottom: 0;
  width: 100%;
  background-color: white;

  .draft-order-footer-description {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-self: flex-end;
    margin: 0 2.5% 0 60%;
  }

  .draft-order-price {
    justify-content: space-between;
    justify-self: flex-end;
    display: flex;
    flex-direction: row;

    .title {
      font-size: 14px;

      &.total {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .price {
      font-size: 14px;

      &.bold {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .description {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      padding: 0 40px;
    }
  }

  .end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .draft-order-footer {

    .draft-order-footer-description {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-content: center;
      margin: 0;
    }

    .draft-order-price {
      justify-content: space-between;
      justify-self: flex-end;
      display: flex;
      flex-direction: row;
      margin: 5px 20px;

      p {
        color: var(--main-color);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }

    .end {
      button {
        height: 48px !important;
        width: 160px !important;
      }

      p {
        margin-right: none;
        line-height: unset;
        visibility: hidden;
      }
    }
  }
}