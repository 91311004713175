.modal-view-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 111;

    &:not(.active) {
        transform: translateX(-100%);
        pointer-events: none;
    }

    &.full-screen {
        opacity: 1 !important;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.14);
        opacity: 0;
        will-change: opacity;
        transition: .3s opacity;
    }

    &.enter {
        &:before {
            opacity: 1;
            animation: showModalViewBackdrop .3s;
        }
    }

    @keyframes showModalViewBackdrop {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &.full-screen {
        position: fixed;

        &:before {
            position: fixed;
        }
    }

    .modal-view-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: max-content;
        gap: 12px;
        transform: translateX(100%);
        will-change: opacity, transform;
        background: #fff;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #fff;
            transform: translateX(100%);
        }

        .modal-view-header {
            padding: 16px 16px 0 16px;
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 12px;
            z-index: 10;

            .dismiss-button {
                display: flex;
                height: 32px;
                width: 32px;
                justify-content: center;
                align-items: center;
				margin-left: -11px;

                &:disabled {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            .mobile-view-title {
                font-size: 18px;
                font-weight: 500;
                color: #3F3F3F;
                -webkit-text-fill-color: #3F3F3F;
                justify-self: flex-start;
            }
        }
    }
}
