@import "icons.scss";

.App {
	font-family: var(--font-family);
	height: 100%;
	border-radius: var(--app-border-radius);
	color: rgba(0, 0, 0, 0.64);
	-webkit-text-fill-color: rgba(0, 0, 0, 0.64);
	background-color: white;
	width: 100%;

	&.inherit-font-family {
		font-family: inherit;
	}

	&.full-screen {
		width: 1200px;
		height: 720px;
		margin: auto;
		padding-top: calc((100vh - 720px) / 2);
		box-shadow: none;
		background-color: unset;
		.wtr-container,
		.error-page,
		.tu-container,
		.scheduler-container {
			width: 480px;
			margin: auto;
			border-radius: var(--app-border-radius);
			background-color: white;
		}
		.wtr-container {
			.wtr-video-preview {
				border-radius: var(--app-border-radius);
				.video-container {
					video {
						border-radius: var(--app-border-radius) var(--app-border-radius) 0 0;
					}
				}
			}
			&.schedule {
				.schedule-logo {
					margin-top: auto;
				}
			}
			.wtr-footer {
				position: unset;
				margin-top: auto;
			}

		}
		.video-room {
			border-radius: var(--app-border-radius);
			background-color: black;
		}
		@media (min-width: 1600px) {
			width: 1400px;
			height: 800px;
			padding-top: calc((100vh - 800px) / 2);
			.video-room {
				&.sidebar-open {
					.drawer-container {
						width: 860px;
					}
				}
				.product-sidebar {
					width: 540px;
					transform: translatex(540px);
					&.open {
						transform: translatex(0);
					}
				}
				.sidebar-btn {
					&.open {
						transform: translateX(-540px);
					}
				}
			}
		}
		@media (min-width: 1025px) and (max-width: 1599px) {
			.video-room {
				&.sidebar-open {
					.drawer-container {
						width: 750px;
					}
				}
				.product-sidebar {
					width: 450px;
					transform: translatex(450px);
					&.open {
						transform: translatex(0);
					}
				}
				.sidebar-btn {
					&.open {
						transform: translateX(-450px);
					}
				}
			}
		}
		@media (min-width: 768px) and(max-width: 1024px) {
			width: 100%;
			height: 700px;
			padding-top: calc((100vh - 700px) / 2);
		}

		@media (max-width: 767px) {
			// Override Sass min()
			@function min($numbers...) {
				@return m#{i}n(#{$numbers});
			}
			width: 100vw;
			height: 100%;
			padding-top: unset;
			.wtr-container {
				border-radius: 0;
				justify-content: flex-start;
				overflow: hidden;
				align-items: center;
			}
		}
		@media (max-width: 767px) {
			.wtr-container {
				.wtr-video-preview {
					border-radius: 0;
					.video-container {
						video {
							border-radius: 0;
						}
					}
				}
			}
		}
		@media (max-width: 480px) {
			.wtr-container,
			.error-page,
			.tu-container,
			.scheduler-container {
				width: 100%;
				border-radius: 0;
				min-height: 350px;
			}

			.caazam-main-container-wrapper {
				min-height: 530px;
			}

			height: 100%;
			min-height: auto;
			overflow-y: auto;
			border-radius: 0;
		}
	}
	.product-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		border-radius: var(--border-radius-s);
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;
	}
	* {
		box-sizing: border-box;
	}
	.error-message {
		color: var(--error-color);
		-webkit-text-fill-color: var(--error-color);
		margin: 10px 0 0 0;
	}
	.error-message-placeholder {
		height: 28px;
		margin-top: 1vh;
	}
	.error-text {
		color: var(--error-color) !important;
		-webkit-text-fill-color: var(--error-color) !important;
		font-size: 14px;
	}
	h3 {
		font-weight: 600;
		color: var(--base-font-color);
		-webkit-text-fill-color: var(--base-font-color);
		font-size: 1.33rem;
	}
	.is-clickable {
		cursor: pointer;
	}
	div {
		&.button-loader {
			animation: button-loader 2s linear infinite;
		}
	}
	@media (max-height: 575px) {
		min-height: 520px;
	}
}
@keyframes button-loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#VideoRoom {
	height: 100vh;
}

.caazam-main-container-wrapper {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	height: 100vh;
	width: 100%;
  	margin: auto;
	position: relative;
}



// common animations
.animated-loader-background {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: darkgray;
        background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
        background-size: 800px 104px;
        border-radius: 4px;
    }
}

@keyframes placeHolderShimmer {
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes onenter-list-item-animation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
