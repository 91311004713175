.copy-link-container {
  position: relative;

  input {
    height: 32px;
    font-size: 14px;
    &::selection {
      background-color: unset;
    }
  }

  button {
    height: 32px;
    width: 80px;
  }
}
