.mobile-cart-products-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;
    height: 100%;

    .mobile-post-purchase-message {
        margin-top: 165px;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        padding: 16px;
    }
}
