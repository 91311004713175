.select-event-list {
    width: 100%;
    display: grid;
    justify-items: center;
    list-style-type: none;
    margin: 0;
    padding: 20px 24px;
}

@media (max-width: 479px) {
    .select-event-list {
        padding: 20px;
    }
}
