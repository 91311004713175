@keyframes animateImageOnload {
    0% {
        opacity: 0;
        transform: scale(.5);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
