.tabs {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 3fr auto;
    gap: 10px;
    align-items: center;
    margin: 10px -8px 0 -8px;
    width: calc(100% + 16px);

    @for $i from 1 to 10 {
        .date-title:nth-child(#{$i}) {
            animation-delay: $i * .05s;
        }
    }

    .slots-tabs-navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        transition: .2s opacity;
        will-change: opacity;
        height: 32px;
        width: 32px;
        touch-action: manipulation;

        &:first-child {
            grid-column: 1;
        }

        &:last-child {
            grid-column: 3;
        }

        &.visible {
            pointer-events: auto;
            opacity: 1;
        }
    }

    .available-days-list-wrapper {
        display: flex;
        grid-column: 2;
        overflow: hidden;


        .available-days-list {
            grid-auto-flow: column;
            display: grid;
            align-items: center;
            grid-auto-flow: column;
            grid-auto-columns: 33.33333%;
            width: 100%;

            .date-tile-wrapper {
                padding: 15px 6px;
                display: flex;

                .date-title {
                    text-align: center;
                    padding: 20px 4px;
                    box-shadow: 1px 0px 6px rgba(0,0,0,0.14);
                    position: relative;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    align-items: center;
                    opacity: 0;
                    animation: onenter-list-item-animation .4s forwards;
                    min-width: 0;

                    &:before {
                        position: absolute;
                        content: '';
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: #F1EFEF;
                        transition: .14s opacity;
                        opacity: 0;
                        z-index: -1;
                    }
            
                    &:not(.scheduled) {
                        .selected-tag {
                          display: none;
                        }
                    }

                    &:hover {
                        &:not(.selected) {
                            &:before {
                                opacity: .5;
                            }
                        }
                    }
            
                    &.selected {
                        &:before {
                            opacity: 1;
                        }
                    }
            
                    .date-tile-content {
                        overflow: hidden;
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        width: 100%;
                        
                        .day-name {
                            color: #484848;
                            -webkit-text-fill-color: #484848;
                            font-size: 16px;
                            font-weight: 600;
                            text-transform: capitalize;
                            text-overflow: ellipsis;
                            display: block;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 100%;
                        }
                
                        .day-tab-item {
                            color: #484848;
                            -webkit-text-fill-color: #484848;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
            
                    .selected-tag {
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }
    }

    &.hidden {
        visibility: hidden;
        pointer-events: none;
    }
}

@media (max-width: 479px) {
    .tabs {
        gap: 0px;
        margin: 0 0 0 -20px;
        width: calc(100% + 40px);
    
        .slots-tabs-navigation {
            height: 30px;
            width: 30px;
        }

        .available-days-list-wrapper {
            .available-days-list {
                .date-title {
                    text-align: center;
                    padding: 18px 2px;
                    box-shadow: 1px 0px 4px rgba(0,0,0,0.14);
            
                    .date-tile-content {
                        .day-name {
                            font-size: 14px;
                        }
                
                        .day-tab-item {
                            font-size: 12px;
                        }
                    }

                    .selected-tag {
                       width: 90%;
                    }
                }
            }
        }
    } 
}
