.sold-out-button {
    margin-top: 0;
    height: 40px;
    width: 100%;
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.32) !important;
    color: rgba(0, 0, 0, 0.32) !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.32) !important;
    background-color: #fff;
    grid-column: 1;
}

.add-to-cart-button {
    margin-top: 0;
    height: 40px;
    width: 100%;
}
