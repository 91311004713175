.video-room-panel {
	overflow: hidden;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;

	&.dragged-over {
		background-color: rgb(240, 240, 240);
	}
	.product-row-wrapper {
		margin-left: 40px;
		overflow-x: auto;
		display: flex;

		.product-row {
			display: flex;
			padding: 24px 0px 10px 0;
			flex-flow: row nowrap;

		.product-container {
			position: relative;
			height: 180px;
			width: 120px;
			min-width: 120px;
			border-radius: var(--border-radius-s);
			margin-right: 10px;
			.product-image {
				object-fit: contain;
				width: 100%;
				height: 100%;
				border-radius: var(--border-radius-s);
			}
		}
	}
	}


	.empty-showroom {
		width: 100%;
		height: calc(100% - 320px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.empty-showroom p {
		width: 360px;
	}

	.post-purchase {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		h1 {
			font-size: 40px;
			font-weight: 600;
			color: black;
			-webkit-text-fill-color: black;
		}
	}

	.showroom-overlay {
		position: absolute;
		background: rgba(0, 0, 0, 0.8);
		top: 0;
		width: 100%;
		height: 230px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		h1 {
			color: white;
			-webkit-text-fill-color: white;
			font-size: 24px;
			font-weight: 600;
			text-align: center;
		}
		div {
			width: 280px;
			justify-content: space-evenly;
			display: flex;
		}
		.filled-button {
			&.keep {
				background-color: white;
				color: black;
				width: 120px;
				height: 40px;
				-webkit-text-fill-color: black;
				min-width: 100px;
			}
			&.remove {
				background-color: var(--error-color);
				width: 120px;
				height: 40px;
				min-width: 100px;
			}
		}
	}

	.divider {
		border: 1px solid #ccc3bd;
		opacity: 0.4;
		width: 95%;
		margin: auto;
	}

	@media (max-width: 767px) {
		.divider {
			width: calc(100% - 2px);
		}
	}
}

.video-room-panel .cart-empty {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.cart_basket-icon {
		margin-bottom: 25px;
	}

	&.mobile {
		height: calc(100% - 124px);
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		margin: 16px;
	}
}

.video-room-panel .cart-empty p {
	width: 360px;
}

.video-room-panel .actions {
	height: 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

@media (min-width: 768px) and (max-width: 1024px) {
	.video-room-panel {
		.empty-showroom {
			height: calc(100% - 380px);
		}
		.product-row-wrapper {
			.product-row {
				.product-container {
					height: 120px;
					width: 80px;
					min-width: 80px;
				}
			}
		}

		.showroom-overlay {
			height: 170px;
		}
	}
}
