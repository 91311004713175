.mobile-product-container {
    position: relative;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr auto;
    gap: 8px;

    .mobile-delete-button {
        touch-action: manipulation;
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px;
        z-index: 4;
        transform: translate3d(0,0,0);

        .mobile-close-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            height: 20px;
            width: 20px;
            background-color: rgba(0, 0, 0, 0.2);

            svg {
                font-size: 1rem;
                color: #fff;
                -webkit-text-fill-color: #fff;
            }
        }
    }

    .mobile-product-image-wrapper {
        aspect-ratio: 1;
    }

    .mobile-product-overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        .mobile-view-product-overlay {
            height: 100%;
            position: relative;
        }
    }

    .mobile-product-image {
        overflow: hidden;
        border-radius: var(--border-radius-s);
        height: 100%;
        object-fit: contain;
        width: 100%;
        border-radius: 6px;
    }

    .empty-image {
        border-radius: 6px;
        aspect-ratio: 1;
    }

    .mobile-imag-with-overlay {
        height: 100%;
        width: 100%;
    }

    .mobile-product-image {
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }

    .mobile-product-description-container {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        gap: 2px;
        font-size: 14px;

        .mobile-product-price-container {
            grid-column: 1;
            grid-row: 1;
            font-weight: 600;
            color: #3F3F3F;
        }

        .mobile-added-to-cart-tag-container {
            grid-column: 2;
            grid-row: 1;
            position: relative;

            .mobile-added-to-cart-tag-checkbox {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .mobile-product-name-container {
            grid-column: 1 / 3;
            grid-row: 2;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #66676C;
        }
    }
}
