.page-header {
    padding: 0 24px 10px 24px;
    display: grid;
    grid-auto-flow: row;
    color: #3F3F3F;
    -webkit-text-fill-color: #3F3F3F;
    justify-items: center;
    width: 100%;

    .page-title {
        font-size: 20px;
        font-weight: 600;
        justify-self: flex-start;
        width: 100%;
        color: #3F3F3F;
        -webkit-text-fill-color: #3F3F3F;
    }

    .page-subtitle {
        font-size: calc(1em - 2px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: left;
    }

    .page-header-child {
        width: 100%;
    }
}

@media (max-width: 479px) {
    .page-header {
        padding: 0 20px 0 20px;

        .page-title {
            font-size: 18px;
        }
    }
}
