.page-footer-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 50px 24px;
    position: relative;

    &.footer-container-shadow {
        box-shadow: 0px -4px 6px rgba(0, 0, 0, .08);
    }

    .actions-container {
        height: 100%;
        width: 100%;
        display: grid;
        grid-auto-flow: row;
        gap: 16px;
        max-height: 120px;
        transition: .5s opacity, .14s max-height;

        &.hidden {
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            max-height: 0;
            height: 0;
        }

        .primary-buttons-wrapper {
            overflow: hidden;
            display: flex;

            .primary-buttons-container {
                display: flex;
                flex-flow: row nowrap;
                gap: 16px;
                width: 100%;

                .boutiq-button {
                    width: auto;
                    padding: 0 20px;
                    flex-grow: 1;
                }
            }
        }

        .secondary-action {
            display: block;
            cursor: pointer;
            justify-self: center;
            font-size: 14px;

            &.empty {
                cursor: default;
                height: 1.43rem
            }
        }
    }
}

@media (max-width: 479px) {
    .page-footer-content {
        padding: 0 24px 24px 24px;

        .actions-container {
            gap: 14px;

            .primary-buttons-wrapper {
                padding-top: 14px;
            }
        }
    }
}
