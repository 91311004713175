.caazam-button {
	font-weight: 600;
	border-radius: var(--border-radius-m);
	height: 56px;
	outline: none;
	margin: 0;
	width: 184px;
	padding: 0 4px;
	.button-loader {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin: auto;
		animation: button-loader 2s linear infinite;
	}
	&.filled-button {
		background-color: var(--main-color);
		border: none;
		color: #ffffff;
		-webkit-text-fill-color: #ffffff;
		.button-loader {
			border: 2px solid white;
			border-top: 2px solid var(--main-color);
		}
	}
	&.outlined-button {
		border: 1px solid var(--main-color);
		color: var(--main-color);
		background-color: #ffffff;
		-webkit-text-fill-color: var(--main-color);
		.button-loader {
			border: 2px solid var(--main-color);
			border-top: 2px solid white;
		}
	}
	&.transparent-button {
		text-transform: capitalize;
		background-color: transparent;
		font-weight: bold;
		height: 64px;
		outline: none;
		margin: 0;
		line-height: 26px;
		opacity: 0.64;
		.button-loader {
			border: 2px solid var(--main-color);
			border-top: 2px solid white;
		}
	}
}

@keyframes button-loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
