.caazam-input {
	border-radius: var(--input-border-radius);
	height: 56px;
	border: 1px solid var(--light-font-color);
	outline: none;
	color: var(--base-font-color);
	-webkit-text-fill-color: var(--base-font-color);
	padding: 16px;
	width: 100%;
	box-sizing: border-box;
	text-align: start;
	&:focus {
		border: 1px solid var(--main-color);
		box-shadow: none;
	}

	&::placeholder {
		color: var(--light-font-color);
		-webkit-text-fill-color: var(--light-font-color);
	}
}

textarea.caazam-input {
	height: unset;
}
