.schedule-message-form-wrapper {
    padding: 12px 24px 20px 24px;

    .schedule-message-form-container {
        display: flex;
        flex-flow: column;
        gap: 24px;
        align-items: flex-start;

        .schedule-form-label {
            font-size: 18px;
            color: #3F3F3F;
            -webkit-text-fill-color: #3F3F3F;
            line-height: 1em;
        }
    }
}

@media (max-width: 479px) {
    .schedule-message-form-wrapper {
        padding: 16px 20px 20px 20px;
    
        .schedule-message-form-container {
            gap: 10px;
    
            .schedule-form-label {
                font-size: 16px;
            }
        }
    } 
}
