.mobile-search-container {
    display: grid;
    grid-auto-flow: row;
    gap: 8px;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    align-items: flex-start;

    .mobile-search-input-wrapper {
        padding: 0 16px;
    }

    .mobile-search-results {
        padding: 0 16px;
    }
}
