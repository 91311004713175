.wtr-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 100%;

	.wtr-form-container {
		position: relative;
		width: 100%;
		flex: 1;		// let the container fill the remianing space after video preview
		form {
			display: grid;
			grid-template-columns: 100%;
			grid-row-gap: 16px;
			align-items: center;
			margin-top: 20px;
			&.type-schedule {
				margin-top: 20px;
			}
			h3 {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				margin: 1rem 0 1.5rem;
				&.closed-message {
					-webkit-line-clamp: 3;
				}
			}
			.form-text {
				display: flex;
				flex-direction: column;
				align-items: left;
			}
		}
		.actions-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			> span {
				text-align: center;
				cursor: pointer;
				width: 150px;
			}
		}
		.chat-link {
			display: flex;
			text-align: center;
			cursor: pointer;
			text-decoration: underline;
			align-self: center;
			justify-content: center;
			font-weight: 500;
		}
		.getting-ready {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

		}
	}
}

.wtr-footer {
	width: 75%;
	color: var(--light-font-color);
	-webkit-text-fill-color: var(--light-font-color);
	margin-bottom: 10px;
	bottom: 0;
	position: absolute;
	> p {
		font-size: 14px;
	}
}

@media (max-width: 767px) {
	.wtr-container {
		justify-content: flex-end;
		overflow: hidden;
		align-items: center;
		.wtr-video-preview {
			border-radius: 0 !important;
			height: calc(100% - 375px);
			max-height: 320px;
			min-height: 160px;
			flex: 10 1 100%;
			.video-container {
				min-height: 180px;
			}
		}
	}
}

@media (max-height: 575px) {
	.preview-controls {
		width: 110px !important;
		button {
			height: 48px !important;
			width: 48px !important;
		}
	}
}


.demo-video {
	transform-origin: top left;
	animation: animateVideoContainer .4s ease-in-out forwards;
	will-change: transform;
}

@keyframes animateVideoContainer {
	0% {
		transform: scale(1);
	}

	100% {

		transform: scale(.5);
	}
}
