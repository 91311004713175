.product-card-details {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: space-between;
    width: 360px;
    overflow: hidden;
    height: 100%;

    h5 {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
    }

    span {
        color: black;
        -webkit-text-fill-color: black;
    }

    .description-container {
        display: flex;
        flex-flow: column;
        height: 100%;

        .product-title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            cursor: pointer;
        }

        .price-container {
            display: flex;
            overflow: hidden;
            flex-grow: 1;
            align-items: flex-end;
            padding-bottom: 1ch;

            .actual-price {
                color: var(--main-color);
                -webkit-text-fill-color: var(--main-color);
                margin-right: 8px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .old-price {
                color: rgba(0, 0, 0, 0.4);
                -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
                text-decoration: line-through;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
    .product-card-details {
        width: auto;
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .product-card-details {
        max-width: 100%;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        justify-content: space-between;
        overflow: hidden;
        height: 100%;

        h5 {
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 16px;
            text-align: left;
        }

        .description-container {
            .product-title {}

            .price-container {
                flex-flow: row wrap;
                padding-bottom: 0;
                padding-top: 4px;

                h5 {
                    color: #000000;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 23px;
                }

                .actual-price {}

                .old-price {
                    opacity: 0.4;
                    color: #000000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 23px;
                }
            }
        }
    }
}

