.scheduler-form-container {
    display: block;
    width: 100%;

    form {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 24px;
        align-items: center;
        width: 100%;

        .form-header-title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 1rem 0 0 .6ch;
            align-self: flex-start;
            overflow: hidden;
        }

        .text-field-container {
            display: grid;
            gap: 8px;
            grid-auto-flow: row;

            .text-field-wrapper {
                position: relative;

                .text-field {
                    border-radius: var(--input-border-radius);
                    height: 50px;
                    background-color: rgba(118, 118, 128, 0.09);
                    outline: none;
                    color: var(--base-font-color);
                    -webkit-text-fill-color: var(--base-font-color);
                    padding: 16px;
                    width: 100%;
                    box-sizing: border-box;
                    text-align: start;
                    position: relative;
                    transition: .2s;

                    &:focus~.text-field-border {
                        opacity: 1;
                    }
                }

                .text-field-border {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    border: 1px solid var(--main-color);
                    will-change: opacity;
                    transition: .2s opacity;
                    pointer-events: none;
                    border-radius: var(--input-border-radius);
                }
            }

            .text-field-error-container {
                margin-top: -8px;
                font-size: 12px;
                height: 1.5em;
                overflow: hidden;
                line-height: 1.5em;
                display: flex;

                .text-field-error-message {
                    color: var(--error-color);
                    -webkit-text-fill-color: var(--error-color);
                    transform: translateY(-100%);
                    opacity: 0;
                    transition: all .25s cubic-bezier(0.075, 0.82, 0.165, 1);
                    will-change: transform, opacity;
                    user-select: none;

                    &.active {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }

        .actions-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .secondary-button {
                flex-grow: 1;
                text-align: center;
                text-decoration: underline;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .chat-link {
        display: flex;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        align-self: center;
        justify-content: center;
        font-weight: 600;
    }
}

@media (max-width: 479px) {
    .form-container {
        padding: 0 1em;
    }
}
