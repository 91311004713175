.mobile-showroom-content-wrapper {
    overflow-y: auto;
    height: 100%;

    .mobile-showroom-products-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, calc(50% - 8px));
        gap: 16px;
        padding: 16px;
        align-items: flex-start;
    
        .mobile-add-products-button {
            height: 54px;
            width: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--main-color);
            border-radius: 50%;
            color: #fff;
            -webkit-text-fill-color: #fff;
            position: absolute;
            bottom: 33px;
            right: 33px;
            box-shadow: 0 0 9px rgba(0, 0, 0, 0.14);
            z-index: 2;
        }
    }
}


