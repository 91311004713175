.cart-footer {
	bottom: 0;
	width: 100%;
	background-color: white;
	display: grid;

	.dicount-applied {
		margin: 8px 24px -8px 24px;
		text-align: right;
	}

	.ios-15-warning {
		margin: 2px 24px 0px;
		justify-self: flex-end;

		p {
			display: block;
			font-size: 14px;
			color: var(--warning-color);
			-webkit-text-fill-color: var(--warning-color);
		}
	}

	.cart-footer-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 8px 24px;
		height: 84px;

		.start {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			h1 {
				font-size: 40px;
				line-height: 58px;
			}
		}

		.end {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-direction: row;
			margin-left: auto;

			.checkout-messages {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				margin: 0px 8px 0px 16px;

				p {
					text-align: right;
				}
			}

		}
	}

	.discount-text {
		color: var(--main-color);
		-webkit-text-fill-color: var(--main-color);
	}

	.cart__local-mail--icon {
		margin-right: 20px;
	}
}

@media (max-width: 767px) {
	.cart-footer {

		background-color: white;

		.dicount-applied {
			margin: 2px 16px;
			text-align: center;
		}

		.ios-15-warning {
			margin: 2px 16px 0px;
		}

		.cart-footer-content {
			margin: 0px 16px;
			height: 72px;
			width: 90%;

			.checkout-actions {
				height: 40px;
				width: 140px;

				button {
					color: #ffffff;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 0;
					line-height: 23px;
					text-align: center;
					height: 40px;
					width: 140px;
					border-radius: var(--border-radius-m);
					background-color: var(--main-color);
				}
			}

			.start {
				align-items: center;
				justify-content: left;

				h1 {
					color: #000000;
					font-size: 24px;
					font-weight: 600;
					letter-spacing: 0;
					line-height: 20px;
				}

				p {
					color: var(--main-color);
					font-size: 12px;
					letter-spacing: 0;
					line-height: 18px;
				}
			}

			p {
				font-size: 14px;
			}

			.end {
				flex-direction: column-reverse;

				button {
					height: 48px !important;
					width: 160px !important;
				}

				p {
					margin-right: none;
					line-height: unset;
					visibility: hidden;
				}
			}
		}
	}
}
