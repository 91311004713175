.event-data-container {
    display: grid;
    grid-auto-flow: column;
    gap: 0 18px;
    cursor: pointer;
    position: relative;
    width: 100%;
    transition: .14s background, .14s color;
    grid-template-columns: auto 1fr;
    padding: 16px 6px;
    border-bottom: 2px solid rgba(182, 182, 182, 0.3);
    animation: onenter-list-item-animation .2s forwards;

    .event-avatar-container {
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding: 8px 0px;

        .event-type-mark {
            border-radius: 50%;
            height: 8px;
            width: 8px;
        }
    }

    .event-details-data-container {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr auto;
        gap: 0 16px;

        .event-name-wrapper {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            width: 100%;
            overflow: hidden;
            grid-row: 1;
            grid-column: 1;

            .event-name {
                z-index: 1;
                color: #3F3F3F;
                font-size: 16px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .event-duration {
            color: #3F3F3F;
            font-size: 12px;
            font-weight: 500;
            z-index: 1;
            grid-row: 2;
            grid-column: 1;
        }

        .boutiq-button {
            grid-column: 2;
            grid-row: 1/3;
            justify-self: flex-end;
            align-self: center;
            min-height: 20px;
            width: auto;
            padding: 6px 16px 0 16px;
            font-size: 16px;
            line-height: 1.1em;
            height: 2em;
            text-align: center;
            position: relative;
            overflow: hidden;

            .event-btn-label {
                transform: translateY(0);
                color: var(--main-color);
                -webkit-text-fill-color: var(--main-color);
                opacity: 1;
                transition: .4s all cubic-bezier(0.075, 0.82, 0.165, 1);
                will-change: opacity, transform;
                white-space: nowrap;
                line-height: 110%;
            }
        
            .event-btn-label-selected {
                transform: translateY(0);
                color: #fff;
                -webkit-text-fill-color: #fff;
                opacity: 0;
                transition: .4s all cubic-bezier(0.075, 0.82, 0.165, 1);
                will-change: opacity, transform;
                white-space: nowrap;
                line-height: 110%;
            }

            &.selected {
                .event-btn-label {
                    transform: translateY(-100%);
                    opacity: 0;
                }
        
                .event-btn-label-selected {
                    transform: translateY(-100%);
                    opacity: 1;
                }
            }

            &:disabled {
                cursor: pointer;
                border: 1px solid transparent;
            }
        }

        .event-next-availability {
            color: #3F3F3F;
            font-size: 14px;
            font-weight: 500;
        }

        .event-bio {
            z-index: 1;
            grid-column: 1/3;
            color: #66676C;
            font-size: 14px;
            margin: 8px 0 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            transition: max-height .4s cubic-bezier(0.075, 0.82, 0.165, 1);
            overflow: hidden;
            max-height: var(--default-max-height);
            animation: close 0s .4s forwards;

            &.open {
                animation: open 0s forwards;
            }

            @keyframes open {
                from {
                    line-clamp: 4;
                    -webkit-line-clamp: 4;
                }

                to {
                    line-clamp: initial;
                    -webkit-line-clamp: initial;
                }
            }

            @keyframes close {
                from {
                    line-clamp: initial;
                    -webkit-line-clamp: initial;
                }

                to {
                    line-clamp: 4;
                    -webkit-line-clamp: 4;
                }
            }
        }

        .event-description-toggle-button {
            justify-self: flex-start;
            color: #66676C;
            font-size: 14px;
            font-weight: 600;
            padding: 6px 6px 6px 0;
            opacity: 0;
            pointer-events: none;
            visibility: hidden;

            &.available {
                opacity: 1;
                pointer-events: all;
                visibility: visible;
            }

            .event-description-toggle-button-animated-labels-container {
                overflow: hidden;
                position: relative;
                height: 1.2em;

                .event-description-toggle-button-label {
                    line-height: 1.2em;
                    transform: translateY(0);
                    opacity: 1;
                    will-change: opacity, transform;
                    white-space: nowrap;
                    text-align: left;
                    transition: .1s opacity, .1s transform ease-in-out;
                }

                &.open {
                    .event-description-toggle-button-label {
                        transform: translateY(-100%);
                        opacity: 0;
                        transition: .1s opacity, .1s transform ease-in-out;

                        &.active {
                            transform: translateY(-100%);
                            opacity: 1;
                            transition: .3s opacity, .3s transform ease-in-out;
                        }
                    }
                }
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(241, 239, 239, 1);
        opacity: 0;
        transition: .14s opacity;
        will-change: opacity;
        pointer-events: none;
    }

    &:hover {
        &:before {
            opacity: .5;
        }
    }

    &.checked {
        background-color: #F1EFEF;
    }

    &.scheduled {
        &.selected {
            &:before {
                opacity: 1;
            }
        }
    }

    &:not(.scheduled) {
        .selected-tag {
            display: none;
            pointer-events: none;
        }
    }
}

@media (max-width: 479px) {
    .event-data-container {
        padding: 14px 4px;
    
        &:hover {
            &:before {
                opacity: 0;
            }
        }

        .event-avatar-container {
            .event-avatar {
                font-size: 18px;
    
                &.initials {
                    font-size: 18px;
                }
            }
        }
    
        .event-details-data-container {
            .event-name-wrapper {
                gap: 8px;
              
                .event-name {
                    font-size: 14px;
                }
            }
    
            .event-next-availability {
                font-size: 12px;
            }
    
            .boutiq-button {
                font-size: 14px;
    
                &:disabled {
                    cursor: pointer;
                }
            }

            .event-bio {
                font-size: 12px;
                margin: 4px 0 0 0;
            }
    
            .event-description-toggle-button {
                font-size: 12px;
                padding: 10px 4px 4px 0;
            }
        }
    }
}
