.mobile-chat-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    background-color: white;
  
    .mobile-new-message-item {
      display: -webkit-box;
      flex-direction: row;
      align-items: flex-start;
  
      &_newmessage {
        align-items: center;
        justify-content: center;
        color: var(--main-color);
        -webkit-text-fill-color: var(--main-color);
        font-size: 16px;
        display: flex;
        flex-direction: row;
        text-align: center;
        width: 100%;
        padding: 0px;
  
        &_delimiter {
          height: 1px;
          background-color: var(--main-color);
          width: 100%;
          margin-left: 10px;
          margin-right: 10px;
          padding: 0px;
          flex: 2;
        }
      }
    }
  }
  
  .mobile-infinite-scroll {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 10px;
  }
  
  .mobile-follow-scroll-button {
    display: none;
  }
  