.schedule-message-title-container {
    display: grid;
    grid-auto-flow: row;
    gap: 4px;
    padding: 0 0 10px 0;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        border-bottom: 2px solid #ECECEC;
    }

    .event-message {
        font-size: 16px;
        color: #3F3F3F;
        -webkit-text-fill-color: #3F3F3F;
        font-weight: 400;
        line-height: 1em;
        padding-bottom: 16px;
    }

    .schedule-message-main-title-container {
        display: grid;
        grid-auto-flow: column;
        gap: 12px;
        justify-items: flex-start;
        grid-template-columns: auto 1fr;
        width: 100%;
        align-items: center;
        color: #3F3F3F;
        -webkit-text-fill-color: #3F3F3F;

        .scheduled-date-time-label {
            display: grid;
            grid-auto-flow: column;
            align-items: baseline;
            gap: 8px;

            .scheduled-date-timezone {
                color: rgba(0, 0, 0, 0.4);
                -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
                font-size: 12px;
                padding-bottom: 2px;
            }
        }
    }

    .schedule-addition-data-rows {
        display: grid;
        grid-auto-flow: row;
    }
}

@media (max-width: 479px) {
    .schedule-message-title-container {
        gap: 2px;
    
        &:after {
            bottom: -8px;
        }
    
        .event-message {
            font-size: 14px;
            padding-bottom: 14px;
        }
    
        .schedule-message-main-title-container {
            gap: 10px;
        }
    }
}
