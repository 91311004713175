.selected-evet-type-duration {
    font-size: 14px;
    font-weight: 500;
    color: #858585;
    -webkit-text-fill-color: #858585;

    .selected-evet-type-duration-data {
        color: #5C5C5C;
        -webkit-text-fill-color: #5C5C5C;
    }
}



@media (max-width: 479px) {
    .selected-evet-type-duration {
        font-size: 12px;
    }
}
