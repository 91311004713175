.option-list-modal {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 5;
    background-color: #fff;
    margin: 8px 0;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
    width: 30vmin;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    background-color: #fff;
    opacity: 0;

    &.top {
        top: auto;
        bottom: 100%;
        animation: optionListFadeInTop .2s ease-in forwards;
    }

    &.bottom {
        top: 100%;
        bottom: auto;
        animation: optionListFadeInBottom .2s ease-in forwards;
    }

    &.right {
        right: 0;
    }

    &.left {
        left: 0;
    }

    &:before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
    }

    &.dark-backdrop {
        &:before {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    @keyframes optionListFadeInTop {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes optionListFadeInBottom {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}
