.mobile-drawer-container {
    position: absolute;
    background-color: transparent;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    top: calc(100% - 64px);
    transition: .4s top ease;

    &.mobile-visible {
        top: 60px;

        .mobile-drawer-content {
            .mobile-drawer-header {
                &:before {
                    opacity: 1;
                }

                border-bottom: 1px solid #f5f5f5;

                .mobile-close-arrow-tick {
                    opacity: 1;
                    pointer-events: all;
                    transform: translateX(-50%);
                }
            }

            .mobile-drawer-tabs-content {
                opacity: 1;
                pointer-events: auto;
                transition: .25s opacity;
            }
        }
        .modal-view-wrapper {
            opacity: 1;
            pointer-events: all;
        }
    }

    .modal-view-wrapper {
        transition: .3s opacity, .3s transform;
        will-change: opacity, transform;
        opacity: 0;
        pointer-events: none;
    }

    .mobile-drawer-content {
        height: 100%;
        position: relative;
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: auto 1fr;

        .mobile-drawer-header {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0;
                background: #fff;
                transition: .25s opacity;
                will-change: opacity;
            }

            .mobile-close-arrow-tick {
                position: absolute;
                -webkit-tap-highlight-color: transparent;
                pointer-events: none;
                bottom: 100%;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
                background-color: transparent;
                width: 40px;
                outline: none;
                filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
                -webkit-filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
                opacity: 0;
                transition: .4s opacity, .4s transform .2s;
            }
        }

        .mobile-drawer-tabs-content {
        	border-top: 1px solid #F5F5F5;
            overflow: hidden;
            height: 100%;
            width: 100%;
            background: #fff;
            opacity: 0;
            transition: .1s .2s opacity;
            will-change: opacity;
            pointer-events: none;
        }
    }
}
