.discount-code-input-container {
    display: grid;
    padding: 14px;
    border-radius: 4px;
    position: relative;
    width: 100%;
    transition: .2s width;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
        border: 1px solid #B5B5B5;
        opacity: 1;
        transition: .2s opacity;
        will-change: opacity;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
        border: 1px solid #FEBE88;
        opacity: 0;
        transition: .2s opacity;
        will-change: opacity;
    }

    .discount-code-input-message-container {
        grid-row: 2;
        grid-column: 2 / 3;
        color: #66676C;
        -webkit-text-fill-color: #66676C;
        z-index: 1;
        overflow: hidden;
        max-height: 0;
        transition: .2s max-height ease-in-out;
        grid-template-columns: 24px 1fr;
        display: grid;
        align-items: center;
        gap: 0 4px;

        .alert-icon-container {
            grid-row: 1;
            grid-column: 1;
            color: #FEBE88;
            -webkit-text-fill-color: #FEBE88;
            display: inherit;
            transition: .2s transform ease-in-out, .2s opacity;
            transform: translateX(-28px);
            opacity: 0;
        }

        .discount-code-succeed-text-message {
            padding-top: 4px;
            overflow: hidden;
            display: flex;
            flex-flow: row nowrap;
            text-transform: lowercase;

            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                display: block;
                white-space: nowrap;
            }
        }


    }


    &.applied {
        &:after {
            opacity: 1;
        }

        &:before {
            opacity: 0;
        }


        .discount-code-input-message-container {
            max-height: 50px;
            transition: .2s max-height .3s ease-in-out;

            .alert-icon-container {
                transition: .2s transform ease-in-out, .2s opacity .1s;
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

}
