.browser-compatibility-modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;

    .browser-compatibility-modal-container {
        position: relative;
        background: #fff;
        border-radius: var(--app-border-radius);
        padding: 2em;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;

        .browser-compatibility-modal-body {
            display: grid;
            grid-auto-flow: row;
            gap: 20px;
            padding: 0 0 1.4em 0;

            .browser-compatibility-modal-title {
                text-align: center;
            }
    
            .browser-compatibility-modal-description {
                text-align: left;
            }

            .copy-link-container {
                .caazam-input {
                    border-radius: var(--app-border-radius);
                }
    
                .caazam-button.filled-button {
                    border-radius: var(--app-border-radius);
                }
            }
        }

        .filled-button {
            justify-self: center;
            height: 40px;
            width: 100px;
            border-radius: var(--app-border-radius);
        }
    }
}
