.text-field-container {
    display: grid;
    gap: 8px;
    grid-auto-flow: row;
    width: 100%;
    position: relative;
    
    &.disabled {
        pointer-events: none;
        opacity: .6;
    }

    .text-field-placeholder {
        color: #66676C;
        -webkit-text-fill-color: #66676C;
        font-size: 16px;
    }

    .text-field-wrapper {
        position: relative;

        .text-field {
            border-radius: var(--input-border-radius);
            height: 50px;
            background-color: rgba(118, 118, 128, 0.09);
            outline: none;
            color: var(--base-font-color);
            -webkit-text-fill-color: var(--base-font-color);
            padding: 16px;
            width: 100%;
            box-sizing: border-box;
            text-align: start;
            position: relative;
            transition: .2s;

            &::placeholder {
                color: #B8B8B8;
                -webkit-text-fill-color: #B8B8B8;
            }

            &.area {
                height: 100%;
            }

            &:focus~.text-field-border {
                opacity: 1;
            }
        }

        .text-field-border {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            border: 1px solid var(--main-color);
            will-change: opacity;
            transition: .2s opacity;
            pointer-events: none;
            border-radius: var(--input-border-radius);
        }
    }
}

@media (max-width: 479px) {
    .text-field-container {
        gap: 6px;
    
        .text-field-placeholder {
            font-size: 14px;
        }
    
        .text-field-wrapper {
    
            .text-field {
                height: 46px;
                padding: 14px;
            }
        }
    }
}
