.mobile-price-container {
    h4 {
        font-size: 14px;
        font-weight: 500;
    }

    :nth-child(1) {
        color: #3F3F3F;
        -webkit-text-fill-color: #3F3F3F;
        font-size: 14px;
    }

    :nth-child(2) {
        margin-left: 8px;
        color: rgba(0, 0, 0, 0.4);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
        text-decoration: line-through;
    }
}
