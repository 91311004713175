.quantity-input {
	display: flex;
	height: 22px;

	* {
		border: 1px solid #cfc8bb;
		box-sizing: border-box;
	}

	.decrease-button {
		border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
	}

	input::-webkit-inner-spin-button,
	input::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	button {
		font-weight: 600;
		color: #cfc8bb;
		-webkit-text-fill-color: #cfc8bb;
		font-size: 16px;
		line-height: 18px;
		width: 22px;

		&:disabled {
			opacity: .4;
		}
	}

	.count-field {
		display: inline-block;
		vertical-align: top;
		max-width: 30px;
		text-align: center;
	}

	.increase-button {
		border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
	}
}

@media (max-width: 767px) {
	.quantity-input {
		box-sizing: border-box;
		width: 74px;

		.count-field {
			width: 40%;
		}
	}
}
