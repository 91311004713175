.modal-overlay {
  .modal {
    padding-top: 32px;
    overflow: hidden;
    border-radius: 6px;
    
    h3,
    p {
      margin-bottom: 16px;
      display: block;
    }
    p {
      font-size: 16px;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button:first-child {
        margin-right: 10px;
      }
    }
  }
}
