.mobile-empty-tab-container {
    display: grid;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    gap: 16px;
    padding: 16px;

    svg {
        grid-column: 1/3;
        justify-self: center;
        margin-top: 95px;
    }

    p {
        grid-column: 1/3;
        text-align: center;
        color: #B5B5B5;
        -webkit-text-fill-color: #B5B5B5;
    }

    button {
        grid-column: 1/3;
        justify-self: center;
        height: 48px;
        margin-bottom: 40px;
        width: 100%;
        padding: 0;
        align-self: flex-end;
        border-radius: 4px;
        text-transform: none;
        font-size: 20px;
        font-weight: 500;
    }
}
