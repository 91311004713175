.scheduler-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr auto;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 80px 0 0 0;
}

@media (max-width: 479px) {
    .scheduler-container {
        padding: 70px 0 0 0;
    }
}
