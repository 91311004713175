.mobile-image-gallery-wrapper {
    height: 72px;
    display: flex;
    transition: .14s ease-in-out height;

    .mobile-image-gallery {
        overflow-x: auto;
        display: flex;
        flex-flow: row nowrap;
        gap: 2px;
        
        img {
            height: 72px;
            width: 52px;
            object-fit: contain;
            cursor: pointer;
        }
    }
}
