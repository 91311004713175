.mobile-full-screen-product-view-container {
    position: relative;
    transition: .2s transform cubic-bezier(0.215, 0.610, 0.355, 1);

    .mobile-product-images-gallery {
        align-items: flex-start;
        display: flex;
        flex-flow: row nowrap;
        position: absolute;
        right: auto;
        top: 0;
        width: 100%;
        left: 0;
        bottom: 0;
        will-change: transform;

        .mobile-product-image-wrapper {
            flex: 1 0 100%;
            height: 100%;
        }
    }
}
