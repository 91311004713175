.boutiq-button {
    min-height: 48px;
    line-height: 1.2em;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    border-radius: var(--boutiq-border-radius-m);
    outline: none;
    margin: 0;
    width: 184px;
    padding: 0 4px;
    transition: .2s background-color;
    text-transform: var(--btn-text-style);
    font-family: var(--button-font-family, var(--font-family));

    &.loading {
        pointer-events: none;
    }

    .button-loader {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: auto;
        animation: button-loader 2s linear infinite;
    }

    &.filled-button {
        background-color: var(--main-color);
        border: none;
        color: var(--filled_btn-text-color, #fff);
        -webkit-text-fill-color: var(--filled_btn-text-color, #fff);

        .button-loader {
            border: 2px solid var(--filled_btn-text-color, #fff);
            border-top: 2px solid transparent;
        }
    }

    &.destructive-button {
        background-color: var(--error-color);
        border: none;
        color: #fff;
        -webkit-text-fill-color: #fff;

        .button-loader {
            border: 2px solid #fff;
            border-top: 2px solid transparent;
        }
    }

    &.outlined-button {
        border: 1px solid var(--main-color);
        color: var(--main-color);
        background-color: #ffffff;
        color: var(--outlined_btn-text-color, var(--main-color));
        -webkit-text-fill-color: var(--outlined_btn-text-color, var(--main-color));

        .button-loader {
            border: 2px solid var(--main-color);
            border-top: 2px solid white;
        }
    }

    &.transparent-button {
        text-transform: capitalize;
        background-color: transparent;
        font-weight: bold;
        height: 48px;
        outline: none;
        margin: 0;
        line-height: 26px;
        opacity: 0.64;

        .button-loader {
            border: 2px solid var(--main-color);
            border-top: 2px solid white;
        }
    }
}

@keyframes button-loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 479px) {
    .boutiq-button {
        font-size: 18px;
    }
}
