.reschedule-header-container {
    display: flex;
    flex-flow: column;
    gap: 44px;
    
    .reschedule-title {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }

    .shop-logo-container-wrapper {
        padding: 0;

        .shop-logo-content-wrapper {
            padding-top: 48px;
        }
    }
}

.reschedule-main-screen-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 24px;
    height: 100%;
    justify-content: space-around;

    .reschedule-confirm-message {
        text-align: center;

        .reschedule-confirmation-email {
            font-weight: 500;
        }
    }
}


@media (max-width: 479px) {
    .reschedule-header-container {
        gap: 30px;
        
        .reschedule-title {
            font-size: 18px;
        }
        .shop-logo-container-wrapper {
            .shop-logo-content-wrapper {
                padding-top: 40px;
            }
        }
    }
}
