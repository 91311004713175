.device-selector {
	&__control {
		box-sizing: border-box;
		border: 1px solid #ccc3bd;
		border-radius: var(--border-radius-m);
		height: 40px;
		font-size: 14px;
	}
	&__menu {
		border-radius: var(--border-radius-xs);
	}
}
