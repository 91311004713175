.product-params-wrapper {
  .product-params {
    font-size: 14px;
    display: grid;
    grid-auto-flow: column;
    gap: 0 16px;
    justify-content: flex-start;
    align-items: flex-start;
    
    .selected-option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .product-card-trash {
    align-self: flex-start;
    cursor: pointer;
    margin: 0 10px;

    svg {
      width: 20px;
      height: 20px;
    }

    .prefix__active-path {
      fill: #be1919;
    }
  }
}

@media (max-width: 767px) {
  .product-params-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    .product-params {
      grid-template-columns: 1fr 1fr;
      width: 100%;

      .quantity-input {
        margin: 0;
      }

      .product-card-trash {
        align-self: flex-start;
        cursor: pointer;
        margin: -12px 0;
        justify-self: flex-end;
        padding: 12px;
      }

      .selected-option {
        text-align: left;
        grid-column: 1/3;
        grid-row: 2;
        font-size: 14px;
      }
    }
  }
}
