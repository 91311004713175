.boutiq-close-button {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #F5F5F5;
}

@media (max-width: 479px) {
    .boutiq-close-button {
        height: 30px;
        width: 30px;
    }
}
