.product-grid-wrapper {
  width: 100%;
  overflow-y: scroll;
  flex: 1;

  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 560px);
    grid-template-rows: 140px;
    column-gap: 40px;
    row-gap: 24px;
    padding: 36px 0;

    .product-card {
      display: grid;
      grid-template-columns: 96px 360px;
      column-gap: 24px;
      padding: 0 40px;
      align-items: center;

      .product-card-image {
        position: relative;
        width: 96px;
        height: 140px;

        .product-overlay {
          .view-item-icon {
            color: white;
            cursor: pointer;
          }

          .delete-item-icon {
            color: var(--error-color);
            cursor: pointer;
          }
        }

        .card-image {
          object-fit: contain;
          width: 96px;
          height: 140px;
          border-radius: var(--border-radius-s);
          align-self: center;
        }
      }
    }

    &.mobile {
      display: grid;
      grid-template-columns: auto;
      align-items: center;
      grid-template-rows: auto;
      grid-column-gap: 8px;
      margin: 0 8px 0 24px;
      grid-row-gap: 38px;

      .product-card {
        .product-card-image {
          margin: 0 9px 0 0;

          .product-overlay {
            .view-item-icon {
            }

            .delete-item-icon {
            }
          }

          .card-image {
          }
        }
      }
    }
  }
}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .product-grid-wrapper {
    .product-grid {
      grid-template-columns: repeat(auto-fill, 100%);

      .product-card {
        grid-template-columns: 96px 1fr;
        padding: 0 8px;
        grid-column-gap: 8px;
      }
    }
  }
}

@media (max-width: 767px) {
  .product-grid-wrapper {

    .product-grid {
      display: flex;
      flex-direction: column;
      padding: 16px 0;

      .product-card {
        grid-template-columns: 72px minmax(100px, 695px);
        column-gap: 18px;
        padding: unset;
        margin: 0;

        .product-card-image {
          width: 59px;
          height: 89px;
          align-self: flex-start;
          margin-left: 9px;

          .product-overlay {
            .view-item-icon {
            }

            .delete-item-icon {
            }
          }

          .card-image {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
