.error-schedule-content {
    display: grid;
    grid-auto-flow: row;
    padding: 80px 24px 0 24px;
    grid-row: 2;
    justify-items: center;

    h3, 
    p {
        text-align: center;
    }
}

.page-footer-content {
    grid-row: 3;
}
