.boutiq-audio-video-settings {
    position: relative;
    transition: .4s opacity;
    will-change: opacity;

    &.disabled {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    .boutiq-audio-video-button {
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #F5F5F5;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
    }

    .locale-item {
        padding: 10px;
        line-height: 1;
        position: relative;
        width: 100%;
        text-align: start;

        &:after,
        &:before {
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            background: rgba(0, 0, 0, .14);
            opacity: 0;
            transition: .14s opacity;
            will-change: opacity;
        }

        &:hover {
            &:before {
                opacity: .4;
            }
        }

        &.selected {
            &:after {
                opacity: 1;
            }
        }
    }
}

@media (max-width: 479px) {
    .boutiq-audio-video-settings {
        .boutiq-audio-video-button {
            height: 30px;
            width: 30px;
            font-size: 14px;
        }
    }
}
