.mobile-product-details-skeleton-preview {
    overflow: hidden;
    align-content: flex-start;
    transition: .25s height, .25s transform;
    will-change: transform;
    display: flex;
    flex-flow: column;
    gap: 12px;
    padding: 0 0 16px 0;

    .mobile-skeleton-main-image-container {
        flex-grow: 1;
        transition: .5s flex-grow;
        margin: 0 16px;
        position: relative;
    }

    .mobile-skeleton-thumbnails-container {
        margin: 0 16px;
        display: grid;
        grid-auto-flow: column;
        gap: 2px;
        justify-content: flex-start;

        .mobile-skeleton-thumbnail {
            position: relative;
            width: 54px;
            height: 72px;
        }
    }

    .mobile-skeleton-description-container {
        height: 22px;
        display: grid;
        gap: 50px;
        grid-template-columns: 1fr 1fr;
        margin: 0 16px;

        .mobile-skeleton-product-name {
            position: relative;
        }

        .mobile-skeleton-product-price {
            position: relative;
        }
    }

    .mobile-skeleton-submin-button-container {
        margin: 0 16px;
        height: 48px;
        position: relative;
    }
}
