.calendar-options-list {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;

    .calendar-option,
    .add-to-calendar-modal-close-button {
        color: rgba(0, 0, 0, 0.64);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        line-height: 1;
        position: relative;
        width: 100%;
        text-align: start;
        cursor: pointer;

        &:before {
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            background: rgba(0, 0, 0, 0.14);
            opacity: 0;
            transition: .14s opacity;
            will-change: opacity;
            cursor: default;
            pointer-events: none;
        }

        &:hover {
            &:before {
                opacity: .4;
            }
        }
    }
}
