.mobile-showroom-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .85);
    z-index: 2;
    display: grid;
    gap: 16px;
    grid-template-rows: max-content;
    padding: 16px;

    .mobile-overlay-checkout-complete-header {
        margin-top: 165px;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
    }

    .mobile-overlay-active-buttons-container {
        display: grid;
        gap: 8px;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;

        .mobile-keep,
        .mobile-remove {
            width: auto;
            text-transform: none;
            font-weight: 500;
            border-radius: 4px;
            font-size: 16px;
            height: 48px;
        }
    }
}
