
.video-room {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: black;
  .video-container {
    width: 100%;
    transition: all 0.4s ease-in-out;
  }
  .height {
    height: calc(100% - 80px);
  }
  .fullscreen-height {
    height: 100%;
  }
  &.ftr-open {
    .video-container {
      height: 412px;
      width: 100%; /* width: 732px; */
      @media (max-width: 1024px) {
        height: calc(100% - 250px);
      }
      @media (min-width: 1400px) {
        height: calc(100% - 310px);
      }
    }
  }

  &.sidebar-open {
    justify-content: flex-start;
    .video-container,
    .drawer-container {
      width: calc(100% - 37.5%);
      @media (max-width: 1024px) {
        width: calc(100% - 360px);
      }
      transition: all 0.4s ease-in-out;
    }
  }

  .product-sidebar {
    background-color: #ffffff;
    position: absolute;
    right: 0;
    z-index: 100;
    width: 37.5vw;
    height: 100%;
    transition: 0.4s transform ease-in-out;
    transform: translatex(37.5vw);
    @media (max-width: 1024px) {
      width: 360px;
      transform: translatex(360px);
    }

    .loader-wrapper {
      height: 100%;
    }

    &.open {
      transform: translateX(0);

      //&.desktop {
      //  display: contents;
      //}
    }

    .close-btn {
      margin-left: 8px;
      margin-top: 8px;
      font-size: 32px;
      line-height: 32px;
    }

    &.desktop {
      .search-container {
        .search-results {
          padding: 0 10px 24px 20px;
        }
      }
      
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 32px;
          border-left: 1px solid #ccc3bd;
        }
    
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 98px;
          bottom: 0;
          border-left: 1px solid #ccc3bd;
        }
    }
  }

  .presenting-text {
    font-weight: bold;
    font-size: large;
    cursor: pointer;
    box-sizing: border-box;
    height: 32px;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 1;
    background-color: var(--main-color);
    color: white;
    -webkit-text-fill-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
  }

  .sidebar-btn {
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    height: 66px;
    width: 74px;
    border-left: 1px solid #ccc3bd;
    border-top: 1px solid #ccc3bd;
    border-bottom: 1px solid #ccc3bd;
    border-radius: 100px 0 0 100px;
    position: absolute;
    right: -1px;
    background-color: white;
    top: 32px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    &.open {
      transform: translateX(-37.5vw);
      transition: 0.4s ease-in-out;
      @media (max-width: 1024px) {
        transform: translatex(-360px);
      }
    }
    &.close {
      transform: translateX(0px);
      transition: 0.4s ease-in-out;
    }
  }
}

.menu-panel-button {
  flex: 1 0 33%;
  justify-content: center;

  p {
    position: relative;
    height: 26px;
    opacity: 0.64;
    color: #FFFFFF;
    -webkit-text-fill-color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    text-shadow: 1px 1px 2px black, 0 0 4px black;

    .counter-badge {
      position: absolute;
      font-size: 12px;
      right: -20px;
      background-color: #fff;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 1em;
      color: #000;
      -webkit-text-fill-color: #000;
      text-shadow: none;
      box-shadow: 0 0 8px rgba(0,0,0,0.64);
    }
  }
}

.search-button {
  width: 33%;
}

.cart-button {
  width: 33%;
}

.showroom-button {
  width: 34%;
}

.drawer-container {
  position: absolute;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  transform: translateY(540px);
  transition: 0.4s ease-in-out;

  &.product-view {
    transform: translateY(310px);
    .ftr-open {
      transform: rotate(180deg);
    }
  }
  &.cart-view {
    transform: translateY(0);
    .ftr-open {
      transform: rotate(180deg);
    }
  }
  .drawer-header {
    height: 80px;
    background-color: #fefaf5;
    box-sizing: border-box;
    border-top: 1px solid #ccc3bd;
    border-left: 1px solid #ccc3bd;
    border-bottom: 1px solid #ccc3bd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;

    .start {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.4);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
      display: flex;
      height: 100%;

      .shop-logo-container-wrapper {
        padding: 0;
        width: 100%;
        max-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;

        .shop-logo-content-wrapper {
          padding-top: 0;
          display: flex;
          height: 50px;
          .shop-logo-content {
            position: relative;

            .shop-logo {
              max-width: 100px;
              position: relative;
              transform: none;
              left: 0;
            }
          }
        }
      }

      > span {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        height: 100%;

        &.showroom {
          margin-right: 44px;
        }

        > p {
          margin-right: 6px;
        }
        .counter {
          display: inline-block;
          text-align: center;
          box-sizing: border-box;
          height: 20px;
          width: 20px;
          min-width: 20px;
          border: 1px solid rgba(0, 0, 0, 0.4);
          border-radius: 50%;
          font-size: 12px;
          line-height: 18px;
          &.emphasize {
            -webkit-text-fill-color: white;
            color: white;
            background-color: black;
          }
        }
        &.active {
          color: black;
          -webkit-text-fill-color: black;
          border-bottom: 3px solid black;
          .counter {
            border-color: black;
          }
        }
      }
    }
    .end {
      p {
        margin-right: 16px;
      }
    }
    .ftr-open {
      box-sizing: border-box;
      height: 49px;
      width: 49px;
      border: 1px solid #ccc3bd;
      border-radius: 50%;
      background-color: #ffffff;
      color: #cfc8bb;
      -webkit-text-fill-color: #cfc8bb;
      font-size: 48px;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .25s transform;
      will-change: transform;
      
      svg {
        font-size: 3rem;
      }
    }
  }
  .drawer {
    height: 540px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .video-room {
    .drawer-container {
      &.product-view {
        transform: translateY(370px);
      }
    }
  }
}
@media (max-width: 767px) {
  .video-room {
    flex-direction: column;
    justify-content: unset;

    /* .drawer-container {
      &.product-view {
        height: 100%;
        transform: translateY(370px);
      }
    } */
  }
  .video-room .drawer-btn {
    -webkit-text-fill-color: white;
    color: white;
    transform: none;
    bottom: 0;
    right: 1vw;
  }
  .video-room .sidebar-btn {
    top: 16px;
  }

  /* this is a hack to override videoroom styles,
  since media queries are not supported in inline styles
  and nwb does not handle css files well */
  #LocalParticipant {
    max-width: 30vw !important;
  }

  #Room {
    width: 100vw !important;
    height: 100vh !important;
  }
}

/* .css-3pxmmn  {
  overflow-y: hidden !important;
}
 */
