.host-data-container {
    .host-avatar-container {
        &.animated-loader-background {
            position: relative;
        }
    }

    .host-details-data-container {
        .host-name-wrapper {
            .host-name {
                &.animated-loader-background {
                    position: relative;
                    width: 50%;
                    height: 21px;
                }
            }
        }

        .host-bio {
            &.animated-loader-background {
                position: relative;
            }
        }

        .host-description-toggle-button {
            .host-description-toggle-button-label {
                &.animated-loader-background {
                    position: relative;
                    line-height: 21px;
                    width: 120px;
                    height: 18px;
                }
            }
        }
    }
}
