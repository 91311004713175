.app-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;

    &.disabled {
        display: none;
    }

    &.no-bar {
        &:before {
            opacity: 0 !important;
        }
    }

    &.visible{
        &:not(.disabled) {
            &::before {
                opacity: 1;
            }
        }
    }

    &:before {
        content: '';
        box-shadow: inset 0 8px 0 0 var(--main-color);
        border-radius: var(--app-header-radius);
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        pointer-events: none;
        position: absolute;
        z-index: 111;
        will-change: opacity;
        opacity: 1;
    }

    .header-buttons-container {
        padding: 24px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .additional-app-headet-buttons {
            display: grid;
            gap: 16px;
            grid-auto-flow: column;
        }
    }
}

@media (max-width: 479px) {
    .app-header {
        &:before {
            opacity: 0 !important;
        }

        .header-buttons-container {
            padding: 24px 20px 16px 20px;
        }
    }
}
