.caazam-main-container-wrapper {
    &.chat-page {
        .boutiq-close-button {
            position: absolute;
            top: 24px;
            right: 24px;
            background-color: #F5F5F5;
        }

        .app-footer-boutiq-mark {
            bottom: 8px;
        }
    }

}

@media (max-width: 479px) {
    .caazam-main-container-wrapper {
        &.chat-page {
            .boutiq-close-button {
                position: absolute;
                top: 24px;
                right: 20px;
                background-color: #F5F5F5;
            }
        }
    }
}
