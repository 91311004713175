.loader-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex: 1 1 100%;
}
/* TODO: create colors constants (border colors don't work on mobile)*/
.loader {
	border-radius: 50%;
	height: 4.67rem;
	width: 4.67rem;
	box-sizing: border-box;
	border: 8px solid white;
	border-bottom: 8px solid var(--main-color);
	border-right: 8px solid var(--main-color);
	border-top: 8px solid var(--main-color);
	animation: spin 2s linear infinite;
	margin: 1em;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
