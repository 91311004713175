.new-waiting-room-container {
    display: grid;
    height: 100%;
    align-items: flex-end;
    z-index: 1;
    grid-template-rows: auto 1fr auto;
    text-align: center;

    .shop-logo-container-wrapper {
        padding: 0;
        align-self: flex-start;
        justify-self: center;
        max-width: 40%;
        position: absolute;
        top: 60px;
        right: 24px; 
    }

    .waiting-room-title {
        height: 100%;
        justify-self: center;        
        position: relative;
        overflow: hidden;
        align-self: flex-start;
        padding: 1ch 2em 0 2em;
        opacity: 0;
        line-height: 1.33em;
        display: flex;
        flex-flow: column;
    
        .waiting-room-title-main {
            font-size: 20px;
            font-weight: 600;
        }

        .waiting-room-title-custom {
            font-size: 20px;
            font-weight: 600;
        }

        &.schedule-data-loaded {
            animation: showBackground .2s .2s forwards;
        }
    }

}

.leave-button {
    margin-bottom: 60px;
    justify-self: center;
    z-index: 1;
}

.waiting-room-bg-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.waiting-room-bg-wrapper {
    height: 100%;
    display: flex;
    flex-flow: column;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.sync-animation-background {
        &:before {
            animation: showBackground 2s .2s forwards;
            background-color: currentColor;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        will-change: opacity;
    }

    .welcome-background-image {
        opacity: 0;
        will-change: opacity;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 2s;
    }
}

.available-hosts {
    overflow: hidden;
    animation: showAvailableHosts .3s ease-in-out forwards;
}

@keyframes showAvailableHosts {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 50px;
    }
}


@keyframes showBackground {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 479px) {
    h1 {
        padding: 1ch 1em 0 1em;
    }
}
