
.mobile-product-grid {
  max-height: 100%;
  overflow-y: auto;
  padding: 16px;

  .mobile-product-card {
    display: grid;
    grid-auto-flow: column;
    padding: 16px 0;
    border-bottom: 2px solid #f5f5f5;
    grid-template-columns: 76px auto 68px;
    gap: 16px;

    &:first-child {
      padding: 0 0 16px 0;
    }

    .mobile-product-card-image {
      aspect-ratio: 1;
    }

    .mobile-product-card-trash {
      align-self: center;
      justify-self: center;
      touch-action: manipulation;
    }

    .mobile-product-card-details {
      font-size: 14px;
      display: grid;
      grid-auto-flow: row;

      .mobile-description-container {
        overflow: hidden;
        display: grid;
        grid-auto-flow: row;

        .mobile-product-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          line-height: 16px;
        }

        .mobile-variant-options-container {
          display: grid;
          grid-auto-flow: column;
          gap: 16px;
          grid-template-columns: max-content;
          line-height: 16px;
          align-self: flex-start;

          .mobile-price-container {
            .mobile-old-price {
              color: rgba(0, 0, 0, 0.4);
              -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
              text-decoration: line-through;
              margin-left: 8px;
            }
          }

          .mobile-options-container {
            position: relative;
            display: grid;

            &:after {
              content: '|';
              position: absolute;
              left: -9px;
              top: 2px;
              color: #C9C9C9;
              -webkit-text-fill-color: #C9C9C9;
            }

            .mobile-product-variant-data {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .mobile-product-params {
        align-self: flex-end;
      }
    }
  }
}
