.mobile-chat-messenger-controls {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    padding: 16px;
    grid-template-columns: auto 1fr auto;
    grid-row: 2;
    align-items: flex-start;

    .mobile-attach-image-button {
        border: 1px solid var(--main-color);
        border-radius: var(--border-radius-xs);
        min-height: 40px;
        min-width: 40px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        svg {
          fill: var(--main-color);
        }
    }

    .mobile-textfield-input {
        border-radius: var(--border-radius-xs);
        border: 1px solid var(--light-font-color);
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        padding-left: 10px;
        padding-top: 8px;
    }

    .mobile-send-button {
        height: 40px;
        width: 40px;
        border-radius: var(--border-radius-xs);
        background-color: var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          fill: white;
        }
    }

    .mobile-file-input {
        display: none;
    }
}
