.mobile-full-screen-product-view-container {
    position: relative;
    transition: .2s transform cubic-bezier(0.215, 0.610, 0.355, 1);

    .mobile-gallery-controls {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .mobile-swipe-left-button,
        .mobile-swipe-right-button {
            touch-action: manipulation;
            -webkit-tap-highlight-color: transparent;

            &:disabled {
                opacity: .4;
                pointer-events: none;
            }
        }

        .mobile-swipe-left-button {
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 1;
            transform: translateY(-50%);
			padding: 1em;
            display: flex;
        }

        .mobile-swipe-right-button {
            position: absolute;
            right: 0;
            top: 50%;
            z-index: 1;
            transform: translateY(-50%);
			padding: 1em;
            display: flex;
        }

        .mobile-product-images-gallery {
            align-items: flex-start;
            display: flex;
            flex-flow: row nowrap;
            position: absolute;
            right: auto;
            top: 0;
            width: 100%;
            left: 0;
            bottom: 0;
            will-change: transform;

            &.mobile-selected-changed {
                transition: .25s transform cubic-bezier(0.215, 0.610, 0.355, 1);
            }

            .mobile-product-image-wrapper {
                flex: 1 0 100%;
                height: 100%;
            }
        }
    }
}
